export const imageOnErrorHandler = (event: any, FALLBACK_IMAGE: any) => {
  event.currentTarget.src = FALLBACK_IMAGE;
};
export function imageHandler(e: any, err: any, upload: any, name?: any) {
  const reader: any = new FileReader();
  const fsize = e.target.files[0].size;
  const file = Math.round(fsize / 1024);
  if (file >= 2048) {
    err(true);
    upload("");
  } else {
    err(false);

    reader.onload = () => {
      if (reader.readyState === 2) {
        // this.setState( reader.result );
        upload(reader.result);
      }
    };
  }

  reader.readAsDataURL(e.target.files[0]);
  name(e.target.files[0].name);
}
