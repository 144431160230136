import React, { useState, useEffect } from "react";
import Moment from "moment";
import UpComingBirthday from "./UpComingBirthday";
import UpcomingHolidays from "./UpcomingHolidays";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import EmployeeLeaves from "../EmployeeLeaves";

// type Props = {}

const DashboardContent = () => {
  const token = localStorage.getItem("token");
  const employeeId: any = localStorage.getItem("user_Id");
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dashboardCheckInOt, setDashboardCheckInOt] = useState({
    in_mode: 0,
    out_mode: 0,
  });

  setTimeout(() => {
    setSuccess(false);
    setErr(false);
  }, 10000);

  const checkInOut = (value: string) => {
    const params = {
      type: value,
      emp_id: employeeId,
    };

    instance({
      url: APILINK.attendance_insert,
      method: "POST",
      data: params,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.data.status == 200) {
          if (value == "in") {
            setSuccess(true);
            setErr(false);
            getCheckInOut();
          } else {
            setErr(true);
            setSuccess(false);
            getCheckInOut();
          }
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getCheckInOut = async () => {
    await instance({
      url: APILINK.checkin_dashboard,
      method: "GET",
      headers: { token: token },
    }).then(async (response) => {
      if (response.status == 200) {
        if (response.data.status !== 401) {
          const responseData = response.data.data.attendance_status[0];
          setDashboardCheckInOt(responseData);
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      }
    });
  };

  useEffect(() => {
    getCheckInOut();
  }, []);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });
  return (
    <div className="page-wrapper">
      <div className="content">
        {success && (
          <div className="alert alert-success" role="alert">
            Successfully Checked In
          </div>
        )}
        {err && (
          <div className="alert alert-danger" role="alert">
            Successfully Checked Out
          </div>
        )}
        <div className="row">
          <>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
              <div className="dash-widget">
                <div className="">
                  <input
                    type="button"
                    className={
                      dashboardCheckInOt.in_mode === 1
                        ? "btn btn-success cursor-not-allowed cin"
                        : "btn btn-success "
                    }
                    value="Check In"
                    onClick={() => checkInOut("in")}
                    disabled={dashboardCheckInOt.in_mode === 1}
                  />
                </div>

                <div className="dash-widget-info text-right">
                  <h3>{Moment(new Date()).format("dddd")}</h3>
                  <span className="widget-title1">
                    {date.toLocaleDateString()}
                    {"   /  "}
                    {date.toLocaleTimeString()}
                    {/* {`${Moment(new Date()).format("YYYY-MM-DD")} / ${Moment(
                      new Date()
                    ).format("HH:MM:ss")}`} */}
                    {/* <i className="fa fa-check" aria-hidden="true"></i> */}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
              <div className="dash-widget">
                <div>
                  <input
                    type="button"
                    className={
                      dashboardCheckInOt.out_mode === 1
                        ? "btn btn-danger cursor-not-allowed cin"
                        : "btn btn-danger"
                    }
                    value="Check Out"
                    onClick={() => checkInOut("out")}
                    disabled={dashboardCheckInOt.out_mode === 1}
                  />
                </div>

                <div className="dash-widget-info text-right">
                  <h3>{Moment(new Date()).format("dddd")}</h3>
                  <span className="widget-title2">
                    {date.toLocaleDateString()}
                    {"   /  "}
                    {date.toLocaleTimeString()}
                    {/* {`${Moment(new Date()).format("YYYY-MM-DD")} / ${Moment(
                      new Date()
                    ).format("HH:MM:ss")}`} */}
                    {/* <i className="fa fa-check" aria-hidden="true"></i> */}
                  </span>
                </div>
              </div>
            </div>
          </>
        </div>
        <div>
          <EmployeeLeaves />
        </div>
        <div className="row">
          <UpcomingHolidays />
          <UpComingBirthday />
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
