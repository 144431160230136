import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { imageOnErrorHandler } from "../Utilities/Helper";
interface HeaderProps {
  title?: string;
  setShowSideBar?: any;
  showSideBar: boolean;
}

const Header: React.FC<HeaderProps> = ({ showSideBar, setShowSideBar }) => {
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const pro_image = localStorage.getItem("pro_image");
  const user_Id: any = localStorage.getItem("user_Id");

  const [id, setId] = useState(
    localStorage.getItem("id") ? localStorage.getItem("id") : 1
  );
  const [subId, setSubId] = useState(
    localStorage.getItem("id") ? localStorage.getItem("subId") : 0
  );
  const navigate = () => {
    localStorage.setItem("id", "1");
  };
  const moveto = () => {
    localStorage.setItem("id", "1");
  };
  /*   const setabId = (id: number, tag: string) => {
    if (tag == "parent") {
      setId(id);
      setSubId(0);
      localStorage.setItem("subId", "0");
      localStorage.setItem("id", id.toString());
    } else {
      setSubId(id);
      localStorage.setItem("subId", id.toString());
    }
  }; */
  return (
    <div className="header d-lg-flex justify-content-between align-items-center">
      <div className="header-left d-lg-flex justify-content-between align-items-center">
        <Link className="logo" onClick={navigate} to="/dashboard">
          <img
            src={require("../assets/img/ew.png")}
            onError={(e: any) =>
              imageOnErrorHandler(
                e,
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                require("../assets/img/ew.png")
              )
            }
            width="35"
            height="35"
            alt=""
          />
          <span>Tracker</span>
        </Link>
        <a id="toggle_btn" onClick={() => setShowSideBar(!showSideBar)}>
          <FaBars />
        </a>
      </div>

      <a
        id="mobile_btn"
        className="mobile_btn float-left"
        onClick={() => setShowSideBar(!showSideBar)}
      >
        <FaBars />
      </a>
      <ul className="nav user-menu float-right">
        <li className="nav-item dropdown has-arrow">
          <a
            href="#"
            className="dropdown-toggle nav-link user-link"
            data-toggle="dropdown"
          >
            <span className="user-img">
              <img
                className="rounded-circle"
                src={
                  pro_image == "null" || pro_image == ""
                    ? require("../assets/img/user.jpg")
                    : pro_image
                }
                onError={(e: any) =>
                  imageOnErrorHandler(
                    e,
                    // eslint-disable-next-line @typescript-eslint/no-var-requires
                    require("../assets/img/user.jpg")
                  )
                }
                width="24"
                alt={`${first_name} ${last_name}`}
              />
              <span className="status online"></span>
            </span>
            <span>{`${first_name} ${last_name}`}</span>
          </a>
          <div className="dropdown-menu">
            <Link
              className="dropdown-item"
              to="/profile"
              onClick={() => {
                localStorage.setItem("employee_id", user_Id),
                  localStorage.setItem("from", "profile");
              }}
            >
              My Profile
            </Link>

            <Link to={"/"} className="dropdown-item">
              Logout
            </Link>
          </div>
        </li>
      </ul>
      <div className="dropdown mobile-user-menu float-right">
        <a
          href="#"
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <Link className="dropdown-item" to="/profile" onClick={moveto}>
            My Profile
          </Link>

          <Link className="dropdown-item" to="/login">
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
