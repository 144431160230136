/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import Moment from "moment";
import { BsCaretDownFill, BsCalendarXFill } from "react-icons/bs";
import { FcLeave } from "react-icons/fc";
import EmployeeLeaves from "../EmployeeLeaves";

type LeaveProps = {
  setLeaveDays: any;
  leaveList: any;
  empLeaveType: any;
  employeList: any;
};

const Leaves: React.FC<LeaveProps> = ({
  setLeaveDays,
  leaveList,
  empLeaveType,
  employeList,
}) => {
  const [dateFrom, setdateFrom] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateTo, setdateTo] = useState(Moment(new Date()).format("YYYY-MM-DD"));
  const [leaveType, setLeaveType] = useState("");
  const [status, setStatus] = useState("");
  // const [leaveDays, setLeaveDays] = useState([]);
  const [leaveStatus, setLeaveStatus] = useState([
    {
      id: 1,
      name: "Leave Status",
    },
    {
      id: 2,
      name: "Pending",
    },
    {
      id: 3,
      name: "Approved",
    },
    {
      id: 4,
      name: "Declined",
    },
  ]);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const navigate = useNavigate();
  // add leave function
  const leaveAdd = () => {
    localStorage.setItem("leave_Id", "");
    navigate("/addleave");
  };

  // filter leave api function call

  const filterLeave = () => {
    const params = {
      role: role == "1" ? "admin" : "",
      leave_type: leaveType,
      status: status,
      date_to: dateTo,
      date_from: dateFrom,
    };
    instance({
      url: APILINK.leave_list,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            setLeaveDays(response.data.data);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const clearData = () => {
    leaveList();
    setLeaveType("");
    setStatus("");
    setdateFrom(Moment(new Date()).format("YYYY-MM-DD"));
    setdateTo(Moment(new Date()).format("YYYY-MM-DD"));
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-8 col-6">
          <h4 className="page-title">Leave Request</h4>
        </div>

        <div className="col-sm-4 col-6 text-right m-b-30">
          <div
            onClick={leaveAdd}
            className="btn btn-primary float-right"
            style={{ borderRadius: "5px" }}
          >
            Add Leave
          </div>
        </div>
      </div>
      <div>
        <EmployeeLeaves />
      </div>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 position-relative">
          <div className="form-group  select-focus">
            <select
              className="select floating w-100 form-control  icons" /* icons */
              onChange={(e) => setLeaveType(e.target.value)}
              value={leaveType}
            >
              {empLeaveType?.map((item: any) => {
                return (
                  <option key={item.value} value={item.value}>
                    {`${item.label} ${item.days}`}
                  </option>
                );
              })}
            </select>
            <BsCaretDownFill className="search-iconone position-absolute" />
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 position-relative">
          <div className="form-group select-focus">
            <select
              className="select floating w-100 form-control icons"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              {leaveStatus?.map((item) => {
                return (
                  <option key={item.id} /*  hidden={item.hidden} */>
                    {item.name}
                  </option>
                );
              })}
              {/* {leave?.map((item) => {
                return (
                  <option key={item.id} hidden={item.hidden}>
                    {item.status}
                  </option>
                );
              })} */}
            </select>
            <BsCaretDownFill className="search-iconone position-absolute" />
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="form-group ">
            <div className="">
              <input
                className="form-control floating datetimepicker"
                type="date"
                onChange={(e) => setdateFrom(e.target.value)}
                value={dateFrom}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="form-group ">
            <div className="">
              <input
                className="form-control floating datetimepicker"
                type="date"
                onChange={(e) => setdateTo(e.target.value)}
                value={dateTo}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-m-2 col-lg-3 col-xl-2 col-12 ">
          <a
            className="btn btn-success btn-block  marbot"
            onClick={filterLeave}
          >
            Search
          </a>
        </div>
        <div className="col-sm-6 col-md-3  col-lg-3 col-xl-2 col-12">
          <a className="btn btn-warning btn-block" onClick={clearData}>
            Clear
          </a>
        </div>
      </div>
    </>
  );
};

export default Leaves;
