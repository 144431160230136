import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import instance from "../../API/apiCall";
import Layouts from "../layouts/Layouts";
import { APILINK } from "../Utilities/ApiLink";
import { imageOnErrorHandler } from "../Utilities/Helper";
import { toWords } from "number-to-words";
import { useReactToPrint } from "react-to-print";

// type Props = {}

const Payslip = () => {
  const employeeId = localStorage.getItem("employee_id");
  const token = localStorage.getItem("token");
  const [singObj, setSingObj] = useState({
    email: "",
    emp_id: "",
    first_name: "",
    last_name: "",
    dob: "",
    doj: "",
    mobile: "",
    designation: "",
    image: "",
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    branch_name: "",
    reason: "",
    dol: "",
    role: "",
    address: "",
    salary: 0,
  });
  const [earningsTotal, setEarningsTotal] = useState(0);
  const [deductionsTotal, setDeductionsTotal] = useState(0);
  const [earnings, setEarnings] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Payslip ${moment(new Date()).format("MMM, YYYY")}`,
  });

  // get getSingleEmploye
  const getSingle = (salary: any) => {
    const params = {};
    instance({
      url: APILINK.payconfig,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          const responseData = response.data.data;
          if (response.data.data.length > 0) {
            const Earnings: any = [];
            const Deductions: any = [];
            let etotal: any = 0;
            let dotal: any = 0;
            responseData.map((item: any) => {
              if (item.type == "Earnings") {
                etotal +=
                  (parseInt(item.percentage) * salary ? salary : 0) / 100;
                const vals: any = {
                  ...item,
                  total:
                    (parseInt(item.percentage) * salary ? salary : 0) / 100,
                };
                Earnings.push(vals);
              } else {
                dotal +=
                  (parseInt(item.percentage) * salary ? salary : 0) / 100;
                const vals: any = {
                  ...item,
                  total:
                    (parseInt(item.percentage) * salary ? salary : 0) / 100,
                };
                Deductions.push(vals);
              }
            });
            setEarningsTotal(etotal);
            setDeductionsTotal(dotal);

            setEarnings(Earnings);
            setDeductions(Deductions);
          }
        } else if (response.data.status == 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const getSingleEmploye = () => {
    const params = {
      id: employeeId,
    };
    instance({
      url: APILINK.employee_get,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          const responseData = response.data.data[0];
          if (response.data.data.length > 0) {
            setSingObj({
              ...responseData,
              dob: moment(responseData.dob).format("YYYY-MM-DD"),
              doj: moment(responseData.doj).format("YYYY-MM-DD"),
              designation: responseData.role_id,
            });
            getSingle(responseData?.salary);
          }
        } else if (response.data.status == 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    employeeId && getSingleEmploye();
  }, []);

  return (
    <Layouts>
      {" "}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-sm-5 col-4">
              <h4 className="page-title">Payslip</h4>
            </div>
            <div className="col-sm-7 col-8 text-right m-b-30">
              <div className="btn-group btn-group-sm">
                {/* <button className="btn btn-white">CSV</button> */}
                <button className="btn btn-white" onClick={handlePrint}>
                  Print
                </button>
                {/*   <button className="btn btn-white">
                  PDF
                </button> */}
              </div>
            </div>
          </div>
          <div className="row" ref={componentRef}>
            <div className="col-md-12">
              <div className="card-box">
                <h4 className="payslip-title">
                  Payslip for the month of{" "}
                  {moment(new Date()).format("MMMM YYYY")}
                </h4>
                <div className="row">
                  <div className="col-sm-6 m-b-20">
                    <img
                      src="assets/img/logo-dark.png"
                      className="inv-logo"
                      alt=""
                    />
                    <ul className="list-unstyled mb-0">
                      <li>Efficientworks Technologies Pvt Ltd </li>
                      <li>No:44, 2nd floor, viduthalai Nagar, s.kolathur.</li>
                      <li>Chennai - 600129.</li>
                    </ul>
                  </div>
                  <div className="col-sm-6 m-b-20">
                    <div className="invoice-details">
                      <img
                        src={require("../assets/img/ew.png")}
                        onError={(e: any) =>
                          imageOnErrorHandler(
                            e,
                            // eslint-disable-next-line @typescript-eslint/no-var-requires
                            require("../assets/img/ew.png")
                          )
                        }
                        width="75"
                        height="75"
                        alt=""
                      />
                      <h3 className="text-uppercase mt-2">Payslip #49029</h3>
                      <ul className="list-unstyled">
                        <li>
                          Salary Date:{" "}
                          <span>
                            {moment(new Date()).format("DD, MMMM, YYYY")}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 m-b-20">
                    <ul className="list-unstyled">
                      <li>
                        <h5 className="mb-0">
                          <strong>
                            {" "}
                            {`${singObj?.first_name && singObj?.first_name} ${
                              singObj.last_name && singObj.last_name
                            }`}
                          </strong>
                        </h5>
                      </li>
                      <li>
                        <span>{singObj.role}</span>
                      </li>
                      <li>Employee ID: {singObj.emp_id}</li>
                      <li>Joining Date: {singObj.doj}</li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div>
                      <h4 className="m-b-10">
                        <strong>Earnings</strong>
                      </h4>
                      <table className="table table-bordered">
                        <tbody>
                          {earnings?.map((item: any) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <strong>{item.name}</strong>
                                  <span className="float-right">
                                    {item.total}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <strong>Total Earnings</strong>
                              <span className="float-right">
                                <strong>{earningsTotal}</strong>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <h4 className="m-b-10">
                        <strong>Deductions</strong>
                      </h4>
                      <table className="table table-bordered">
                        <tbody>
                          {deductions?.map((item: any) => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <strong>{item.name}</strong>
                                  <span className="float-right">
                                    {item.total}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <strong>Total Deductions</strong>
                              <span className="float-right">
                                <strong>{deductionsTotal}</strong>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <p>
                      <strong>
                        Net Salary: {earningsTotal - deductionsTotal}
                      </strong>{" "}
                      ({toWords(earningsTotal - deductionsTotal).toUpperCase()}{" "}
                      ONLY)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-box">
          <div className="msg-sidebar notifications msg-noti">
            <div className="topnav-dropdown-header">
              <span>Messages</span>
            </div>
            <div className="drop-scroll msg-list-scroll" id="msg_list">
              <ul className="list-box">
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Richard Miles </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item new-message">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">John Doe</span>
                        <span className="message-time">1 Aug</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Tarah Shropshire{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Mike Litorus</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Catherine Manseau{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">D</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">
                          {" "}
                          Domenic Houston{" "}
                        </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">B</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Buster Wigton </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">R</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Rolland Webber </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">C</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author"> Claire Mapes </span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">M</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Melita Faucher</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">J</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Jeffery Lalor</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">L</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Loren Gatlin</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="chat.html">
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">T</span>
                      </div>
                      <div className="list-body">
                        <span className="message-author">Tarah Shropshire</span>
                        <span className="message-time">12:28 AM</span>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <a href="chat.html">See all messages</a>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default Payslip;
