import React from "react";
import { AddExpenses } from "../components/customs/Expenses";
import Layouts from "../layouts/Layouts";

// type Props = {}

const AddExpense = () => {
  return (
    <Layouts>
      <AddExpenses />
    </Layouts>
  );
};

export default AddExpense;
