import React from "react";
import { ForgetPwd } from "../components/customs/Login";

// type Props = {}

const ForgetPassword = () => {
  return <ForgetPwd />;
};

export default ForgetPassword;
