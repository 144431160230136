import React from "react";
import { AddSalary } from "../components/customs/EmployeeSalary";
import Layouts from "../layouts/Layouts";

// type Props = {}

const AddEmployeSalary = () => {
  return (
    <Layouts>
      <AddSalary />
    </Layouts>
  );
};

export default AddEmployeSalary;
