import React, { useEffect, useState } from "react";
import Layouts from "../layouts/Layouts";
import { Attendances, AttendanceTable } from "../components/customs/Attendance";
import Moment from "moment";
import instance from "../../API/apiCall";
import { APILINK } from "../Utilities/ApiLink";
import Spinner from "react-bootstrap/esm/Spinner";
// type Props = {}

const Attendance = () => {
  const [years, setYears] = useState(Moment(new Date()).format("YYYY-MM"));

  const token = localStorage.getItem("token");
  const [search, setSearch] = useState([]);
  const [count, setCount] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const [dates, setDates] = useState([{}]);
  const getYear = () => {
    setLoading(true);
    const params = {
      year: Moment(years).format("YYYY"),

      month: Moment(years).format("MM"),
    };
    instance({
      url: APILINK.attendance_list,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.data.status == 200) {
          setDates(Object.keys(response.data.data[0]));

          setCount(response.data.data[0]);
          const countOne = response?.data.data?.map((item: any) => {
            const keyItem = Object.keys(item);
            let value = 0;
            let valueOne = 0;
            keyItem.map((data) => {
              if (item[data] == "P") {
                value += 1;
              }
              if (item[data] == "L") {
                valueOne += 1;
              }
            });
            return { ...item, present: value, absent: valueOne };
          });
          setSearch(countOne);
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getYear();
  }, []);
  return (
    <Layouts>
      <div className="page-wrapper">
        <div className="content">
          <Attendances
            years={years}
            setYears={setYears}
            getYear={getYear}
            dates={dates}
            search={search}
          />
          <>
            {loading ? (
              <div className="d-flex justify-content-center mt-10 align-items-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <AttendanceTable search={search} dates={dates} />
            )}
          </>
        </div>
      </div>
    </Layouts>
  );
};

export default Attendance;
