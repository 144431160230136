/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef } from "react";
import { FaCheck, FaTimes, FaCalendarTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
type AttendanceTableProps = {
  search: any;
  dates: any;
};
const AttendanceTable: React.FC<AttendanceTableProps> = ({ search, dates }) => {
  const navigate = useNavigate();
  const gotoSingleCalender = (id: any) => {
    localStorage.setItem("user_Id", id);
    navigate("/profile");
  };

  const Role = localStorage.getItem("role");

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table table-striped custom-table mb-0">
              <thead>
                <tr>
                  <th>Employee</th>
                  {dates?.map((date: any, index: any) => {
                    if (
                      date !== "employee_name" &&
                      date !== "id" &&
                      date !== "present" &&
                      date != "absent" &&
                      date !== "in_mode" &&
                      date !== "out_mode" &&
                      date !== "in_time" &&
                      date !== "out_time"
                    ) {
                      return <th key={index}> {index + 1}</th>;
                    }
                  })}
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {search?.map((item: any) => {
                  const count = item.present;
                  const dates = Object.keys(item);

                  return (
                    <>
                      <tr key={item.id}>
                        <td
                          onClick={() => {
                            Role == "1" && gotoSingleCalender(item.id);
                          }}
                        >
                          {item.employee_name}
                        </td>
                        {dates?.map((date) => {
                          if (
                            date !== "employee_name" &&
                            date !== "id" &&
                            date !== "present" &&
                            date != "absent" &&
                            date !== "in_mode" &&
                            date !== "out_mode" &&
                            date !== "in_time" &&
                            date !== "out_time"
                          ) {
                            return (
                              <>
                                <td key={date}>
                                  {item[date] == "A" ? (
                                    <FaTimes className="text-danger" />
                                  ) : item[date] == "L" ? (
                                    <FaCalendarTimes className="text-warning" />
                                  ) : (
                                    <FaCheck className="text-success" />
                                  )}
                                </td>
                              </>
                            );
                          }
                        })}
                        <td>{`${count} / ${
                          dates.length - (item.absent + 8)
                        }`}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-contents-center ">
              <div className="p-2 d-flex align-items-center">
                <FaCheck className="text-success mr-2" />
                Present
              </div>
              <div className="p-2 d-flex align-items-center">
                <FaTimes className="text-danger mr-2" />
                Absent
              </div>
              <div className="p-2 d-flex align-items-center">
                <FaCalendarTimes className="text-warning mr-2" />
                Holiday
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceTable;
