import React from 'react'


interface ButtonProps {
  text:any;
  className?: any;
  style?: any;
  onClick?: any;
  type:any;

  
  
}
const Button: React.FC<ButtonProps> = ({text,className,onClick,type}) => {
  return (
    <button onClick={onClick} type={type} className={className}>
        {text}
    </button>
  )
}

export default Button