import React from "react";
import { RegisterContainer } from "../components/customs/Login";

// type Props = {}

const Register = () => {
  return <RegisterContainer />;
};

export default Register;
