import React, { useEffect, useState } from "react";
import Moment from "moment";
import instance from "../../API/apiCall";
import { ExpensesHeaader, ExpensesTable } from "../components/customs/Expenses";
import Layouts from "../layouts/Layouts";
import { APILINK } from "../Utilities/ApiLink";

// type Props = {}

const Expenses = () => {
  const [expense, setExpense] = useState([]);
  const [expenses, setExpenses] = useState([]);

  // local storage value
  const token = localStorage.getItem("token");

  // Get expenses api function call

  const expensesList = async () => {
    await instance({
      url: APILINK.expenses,
      method: "GET",
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        if (response.data.status !== 401) {
          const expen = response.data.data.map((item: any) => {
            return {
              ...item,
              purchaseDate: Moment(item.purchase_date).format("DD/MM/YYYY"),
            };
          });
          setExpenses(expen);
          setExpense(response.data.data);
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      }
    });
  };

  useEffect(() => {
    expensesList();
  }, []);

  return (
    <Layouts>
      <div className="page-wrapper">
        <div className="content">
          <ExpensesHeaader
            setExpense={setExpense}
            expensesList={expensesList}
            expense={expenses}
          />
          {expense.length > 0 ? (
            <ExpensesTable expensesList={expensesList} expense={expense} />
          ) : (
            <h3 className="d-flex justify-content-center">No data found!</h3>
          )}
        </div>
      </div>
    </Layouts>
  );
};

export default Expenses;
