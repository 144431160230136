import React from "react";
import Layouts from "../layouts/Layouts";
import { AddHoliday } from "../components/customs/Holidays";

type AddHolidaysProps = { title?: any };

const AddHolidays: React.FC<AddHolidaysProps> = () => {
  return (
    <>
      <Layouts>
        <AddHoliday />
      </Layouts>
    </>
  );
};

export default AddHolidays;
