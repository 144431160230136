import React, { useState } from "react";
import { Link } from "react-router-dom";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import Button from "../../basics/button/Button";

// type Props = {}

const ForgetPwd = () => {
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const getForgetPwd = (e: any) => {
    e.preventDefault();
    instance({
      url: APILINK.forgetpsw,
      method: "GET",
      params: { email: email },
    })
      .then((response) => {
        if (response.data.status == 1) {
          setErr("");
          setSuccess(response.data.message);
        }
        if (response.data.status == 0) {
          setSuccess("");
          setErr(response.data.message);
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  return (
    <div className="main-wrapper account-wrapper">
      <div className="account-page">
        <div className="account-center">
          <div className="account-box">
            <form className="form-signin" onSubmit={getForgetPwd}>
              <div className="account-logo">
                <Link to={"/dashboard"}>
                  <img src={require("../../../assets/img/ew.png")} alt="" />
                </Link>
              </div>
              {success && (
                <div className="alert alert-success" role="alert">
                  {success}
                </div>
              )}
              {err && (
                <div className="alert alert-danger" role="alert">
                  {err}
                </div>
              )}
              <div className="form-group">
                <label>Enter Your Email</label>
                <input
                  type="email"
                  className="form-control"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group text-center">
                {/* component create for button at 03/03/23 N.A */}
                <Button className="btn btn-primary account-btn" type="submit" text="Reset Password"/>
                
              </div>
              <div className="text-center register-link">
                <Link to={"/"}>Back to Login</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPwd;
