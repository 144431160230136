import React, { useState } from "react";
import Header from "./Header";
import SideBar from "./SideBar";

type LayoutsProps = { title?: string; children: any };

const Layouts: React.FC<LayoutsProps> = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div className={showSideBar ? "mini-sidebar" : ""}>
      <Header setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
      <SideBar showSideBar={showSideBar} />
      {children}
    </div>
  );
};

export default Layouts;
