import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../../../../API/apiCall";
import Layouts from "../../../layouts/Layouts";
import ProjectAdd from "../../../pages/ProjectAdd";
import { APILINK } from "../../../Utilities/ApiLink";
import { Leave, LeavesTable } from "../Leaves";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import { GrMoreVertical } from "react-icons/gr";
import Addproject from "./addproject";
import { FaEllipsisV, FaMap, FaPencilRuler, FaTrash } from "react-icons/fa";

//

// type AddProjectProps = {};

const Projects = () => {
  const [taskname, setTaskname] = useState("");
  const [category, setcategory] = useState("");
  const [statuses, setstatuses] = useState("");
  const [arrayVal, setArrayVal] = useState([]);
  const [singleGet, setSingleGet] = useState([]);
  const [users, setusers] = useState("");
  const [employeeDetailsOne, setEmployeeDetailsOne] = useState([]);

  const [startdste, setstartdate] = useState("");
  const [comments, setcomments] = useState("");

  const [showButtons, setShowButtons] = useState(false);

  function handleClick() {
    setShowButtons(!showButtons);
  }
  const getProjects = async () => {
    await instance({
      // url of the api endpoint (can be changed)
      url: APILINK.project,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          setArrayVal(response.data.data);
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // local storage value
  const navigate = useNavigate();

  // add leave function
  const addProject = () => {
    localStorage.setItem("leave_Id", "");

    navigate("/addproject");
  };

  const [id, setId] = useState();

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_Id");
  const Role = localStorage.getItem("role");

  //edit

  const editLeave = (id: any) => {
    localStorage.setItem("leave_Id", id);
    navigate("/addproject");
  };

  // onMaping
  const onMaping = (id: any) => {
    navigate("/projctmap");
    localStorage.setItem("projectId", id);
    instance({
      url: `${APILINK.project}/${id} `,
      method: "GET",
      params: {},
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          setSingleGet(response.data.data[0]);
          if (response.data.status !== 401) {
            console.log(response.data.data);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
    navigate("/projctmap");
  };

  //  delete the Card

  // const deleteLeave = () => {};
  const projectGet = () => {
    const params = {};

    instance({
      url: APILINK.project,
      method: "GET",
      params: {},
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            console.log(response.data.data);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const onDelete = (id: any) => {
    const params = {
      id: id,
    };
    instance({
      url: `${APILINK.project}/${id}`,
      method: "DELETE",
      data: params,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          // window.location.href = "/";
          getProjects();
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        } else {
          // window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const employeeDetails = async () => {
    await instance({
      url: APILINK.employee_get,
      method: "GET",
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        setEmployeeDetailsOne(response.data.data);
      } else if (parseInt(response.data.status) === 401) {
        window.location.href = "/";
      } else {
        // window.location.href = "/";
      }
    });
  };
  useEffect(() => {
    getProjects();
    projectGet();
    employeeDetails();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-8 col-6">
          <h4 className="page-title">Project Page</h4>
        </div>

        <div className="col-sm-4 col-6 text-right m-b-30">
          <div
            onClick={addProject}
            className="btn btn-primary btn-rounded float-right"
          >
            Add project
          </div>
        </div>
      </div>
      <div className="divcard">
        {arrayVal.map((item: any) => {
          return (
            <div key={item.id} className="col-md-12">
              <div className="card p-4 mb-2 ">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    <div className="ms-3 c-details">
                      <h3 className="mb-2">{item.project_title}</h3>
                    </div>
                  </div>

                  <div className="text-center">
                    <div className="dropdown dropdown-action">
                      <a
                        href="#"
                        className="action-icon dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <FaEllipsisV />
                      </a>

                      <div className="dropdown-menu dropdown-menu-right">
                        <a
                          className="dropdown-item"
                          onClick={() => editLeave(item.id)}
                        >
                          <FaPencilRuler /> Edit
                        </a>

                        <a
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#delete_expense"
                          onClick={() => onDelete(item.id)}
                        >
                          <FaTrash /> Delete
                        </a>
                        <a
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#delete_expense"
                          onClick={() => onMaping(item.id)}
                        >
                          <FaMap /> project maping
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className="dropdown">
                    <button
                      className="btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <GrMoreVertical />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" onClick={handleClick}>
                        Edit
                      </a>
                      <a className="dropdown-item" onClick={handleClick}>
                        Delete
                      </a>
                    </div>
                  </div> */}
                </div>
                <div className="mt-3">
                  <p className="heading font-weight-600">{item.description}</p>
                  <div className="mt-3">
                    <div className="progress" style={{ height: "10px" }}>
                      <div
                        className="progress-bar"
                        style={{ width: "60%", height: "10px" }}
                      ></div>
                    </div>

                    <div className="mt-3 d-flex justify-content-between align-items-center">
                      <span className="colorhead">{item.status}</span>

                      <div className="display-image">
                        <img
                          className="box-card-img"
                          src={"nouser.jp"}
                          alt=""
                        />
                        <img
                          className="box-card-img secondImage"
                          src={item.image}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default Projects;
