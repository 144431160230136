import React, { useEffect, useState } from "react";
import Layouts from "../layouts/Layouts";
import { EmployeeSingleWorkProgress } from "../components/customs/WorkProgress";
import moment from "moment";
import instance from "../../API/apiCall";
import { APILINK } from "../Utilities/ApiLink";

// type Props = {}

const SingleWorkProgress = () => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const user_Id = localStorage.getItem("user_Id");
  const [workProgress, setWorkProgress] = useState([]);
  const [singleData, setsingleData] = useState([]);
  const [years, setYears] = useState(moment(new Date()).format("YYYY-MM"));
  const [dates, setDates] = useState([{}]);
  const id = localStorage.getItem("wp_user_Id");
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const getYear = () => {
    setLoading(true);
    const params = {
      year: moment(years).format("YYYY"),

      month: moment(years).format("MM"),
      id: id,
    };
    instance({
      url: APILINK.attendance_insert,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.data.status == 200) {
          if (response.data.status != 401) {
            const responseData = response.data.data;
            console.log(responseData, "res");
            setWorkProgress(responseData);
            setsingleData([]);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getYear();
  }, []);
  return (
    <Layouts>
      <EmployeeSingleWorkProgress
        WorkProgress={workProgress}
        singleDataone={singleData}
        // getWorkProgress={getWorkProgress}
        years={years}
        setYears={setYears}
        getYear={getYear}
        dates={dates}
        search={search}
      />
    </Layouts>
  );
};

export default SingleWorkProgress;
