import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import SingleWorkProgressTable from "./SingleWorkProgressTable";
import WorkProgressTable from "./WorkProgressTable";
import { SingleWorkProgress } from "../../../pages";

type EmployeeSingleWorkProgressprops = {
  WorkProgress: any;
  singleDataone: any;
  // getWorkProgress: any;
  years: any;
  setYears: any;
  getYear: any;
  dates: any;
  search: any;
};
const EmployeeSingleWorkProgress: React.FC<EmployeeSingleWorkProgressprops> = ({
  WorkProgress,
  singleDataone,
  // getWorkProgress,
  years,
  setYears,
  getYear,
  dates,
  search,
}) => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("wp_user_Id");
  const from = localStorage.getItem("from");
  const role = localStorage.getItem("role");
  const employeeId: any = localStorage.getItem("user_Id");
  console.log(id, "iddd");
  const [singleData, setsingleData] = useState([
    { first_name: "", last_name: "" },
  ]);

  const getSingleWorkProgress = () => {
    instance({
      url: APILINK.attendance_insert,
      method: "GET",
      params: { id: id },
      headers: {
        token: token,
      },
    })
      .then(
        (response: { status: number; data: { status: number; data: any } }) => {
          if (response.status == 200) {
            if (response.data.status != 401) {
              const responseData = response.data.data;
              setsingleData(responseData);
            } else if (response.data.status === 401) {
              window.location.href = "/";
            }
          }
        }
      )
      .catch((err: any) => {
        console.log(err, "err");
      });
  };
  useEffect(() => {
    getSingleWorkProgress();
  }, []);
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">
              Working Time For -{" "}
              {singleData &&
                `${singleData[0]?.first_name} ${singleData[0]?.last_name}`}
            </h4>
          </div>

          {role == "1" && (
            <Link to={`/${from}`} className="btn btn-primary mr-3">
              Back
            </Link>
          )}
        </div>
        <div className="row filter-row">
          <div className="col-sm-6 col-md-4">
            <div className="form-group "></div>
          </div>

          <div className="col-sm-6 col-md-4">
            <div className="form-group  ">
              <input
                type="month"
                className="select floating w-100 form-control"
                value={years}
                onChange={(e) => setYears(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <a className="btn btn-success btn-block" onClick={getYear}>
              Search
            </a>
          </div>
        </div>
        {/*<div className="row filter-row">
          <div className="col-sm-6 col-md-3">
            <div className="form-group ">
              <input
                type="text"
                className="form-control floating"
                placeholder="Employee ID"
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="form-group ">
              <input
                type="text"
                className="form-control floating"
                placeholder="Employee Name"
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="form-group  select-focus">
              <select className="select floating w-100 form-control">
                {EmployeeDetails &&
                  EmployeeDetails.map((item) => {
                    return <option key={item.id}>{item.option}</option>;
                  })}
              </select>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <a href="#" className="btn btn-success btn-block">
              Search
            </a>
          </div>
        </div> */}

        {WorkProgress.length > 0 ? (
          <SingleWorkProgressTable singleData={WorkProgress} />
        ) : (
          <h3 className="d-flex justify-content-center">No Data Found</h3>
        )}
      </div>
    </div>
  );
};

export default EmployeeSingleWorkProgress;
