import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { APILINK } from "../../../Utilities/ApiLink";
import { useNavigate } from "react-router-dom";
import instance from "../../../../API/apiCall";
import Button from "../../basics/button/Button";
// type Props = {}

const LoginContainer = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [singObj, setSingObj] = useState({
    email: "",
    password: "",
  });
  const LoginInSchema = Yup.object().shape({
    email: Yup.string().email("Email not valid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(5, "Password is too short should be 5 chars minimum"),
  });

  const onSubmit = (values: any) => {
    instance({
      url: APILINK.employee_login,
      method: "POST",
      data: values,
    })
      .then(async (response) => {
        if (response.data.status == 1) {
          const responseData = response.data.data[0];
          localStorage.setItem("designation", responseData.designation);
          localStorage.setItem("dob", responseData.dob);
          localStorage.setItem("email", responseData.email);
          localStorage.setItem("first_name", responseData.first_name);
          localStorage.setItem("user_Id", responseData.id);
          localStorage.setItem("employee_id", responseData.id);
          localStorage.setItem("is_active", responseData.is_active);
          localStorage.setItem("is_admin", responseData.is_admin);
          localStorage.setItem("last_name", responseData.last_name);
          localStorage.setItem("mobile", responseData.mobile);
          localStorage.setItem("password", responseData.password);
          localStorage.setItem("role", responseData.role);
          localStorage.setItem("pro_image", responseData.image);
          localStorage.setItem("subId", "0");
          localStorage.setItem("id", "1");
          localStorage.setItem("token", response.data.token);
          navigate("/dashboard");
          setErr("");
        }
        if (response.data.status == 0) {
          setErr(response.data.message);
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  return (
    <div className="main-wrapper account-wrapper">
      <div className="account-page">
        <div className="account-center">
          <div className="account-box">
            <Formik
              initialValues={singObj}
              onSubmit={(values: any) => onSubmit(values)}
              validationSchema={LoginInSchema}
              enableReinitialize={true}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                touched,
                errors,
              }) => (
                <>
                  <div className="account-logo">
                    <Link to={"/dashboard"}>
                      <img src={require("../../../assets/img/ew.png")} alt="" />
                    </Link>
                  </div>
                  {err && (
                    <div className="alert alert-danger" role="alert">
                      {err}
                    </div>
                  )}
                  <div className="form-group">
                    <label>
                      Email
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <p className="text-danger">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Password <span className="text-danger">*</span>
                    </label>

                    <input
                      type="password"
                      className="form-control"
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <p className="text-danger">{errors.password}</p>
                    )}
                  </div>
                  <div className="form-group text-right">
                    <Link to={"/forgetpass"}>Forgot your password?</Link>
                  </div>
                  <div className="form-group text-center">
                    {/* <Link to={"/dashboard"}> */}

                    {/* component create for button at 03/03/23 N.A  */}
                    <Button
                      type="button"
                      className="btn btn-primary account-btn"
                      onClick={() => handleSubmit()}
                      text="login"
                    />

                    {/* <button
                      type="button"
                      className="btn btn-primary account-btn"
                      onClick={() => handleSubmit()}
                    >
                      Login
                    </button> */}
                    {/* </Link> */}
                  </div>
                  {/* <div className="text-center register-link">
                    Don’t have an account?
                    <Link to={"/register"}>Register Now</Link>
                  </div> */}
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
