import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Layouts from "../layouts/Layouts";
import ProjectAdd from "../pages/ProjectAdd";
import Addmaping from "../pages/Addmaping";

import {
  Attendance,
  Dashboard,
  Employees,
  EmployeeSalary,
  Expenses,
  Holidays,
  Leaves,
  Payslip,
  WorkProgress,
  EmployeesList,
  EditProfile,
  Profile,
  AddExpense,
  AddEmployeSalary,
  AddHolidays,
  Register,
  Login,
  ForgetPassword,
  SingleWorkProgress,
} from "../pages";
import AddLeave from "../components/customs/Leaves/AddLeave";
import AddEmployee from "../components/customs/Employee/AddEmployee";
import Projects from "../components/customs/Projectpage/Projects";
import Addproject from "../components/customs/Projectpage/addproject";
import Pojectmap from "../pages/Pojectmap";

// type Props = {};

const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetpass" element={<ForgetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/workprogress" element={<WorkProgress />} />
        <Route path="/employeeslist" element={<EmployeesList />} />
        <Route path="/employeeSalary" element={<EmployeeSalary />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/holidays" element={<Holidays />} />
        <Route path="/leaves" element={<Leaves />} />
        <Route path="/project" element={<ProjectAdd />} />
        <Route path="/project" element={<Projects />} />
        <Route path="/payslip" element={<Payslip />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/addexpenses" element={<AddExpense />} />
        <Route path="/addemployesalary" element={<AddEmployeSalary />} />
        <Route path="/addleave" element={<AddLeave />} />
        <Route path="/addproject" element={<Addproject />} />
        <Route path="/addmaping" element={<Addmaping />} />
        <Route path="/addemployee" element={<AddEmployee />} />
        <Route path="/addHolidays" element={<AddHolidays />} />
        <Route path="/projctmap" element={<Pojectmap />} />
        <Route path="/singleprogress" element={<SingleWorkProgress />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

function NotFound() {
  return <Layouts>404</Layouts>;
}
export default Routers;
