import React, { useEffect, useState } from "react";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import Moment from "moment";
import { imageOnErrorHandler } from "../../../Utilities/Helper";

// type Props = {}

const UpComingBirthday = () => {
  const [employeList, setEmployeList] = useState([]);
  const token = localStorage.getItem("token");
  const date = new Date();

  const getEmployee = () => {
    instance({
      url: APILINK.employee_get,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status != 401) {
            const responseData = response.data.data;
            const emp = responseData.filter((item: any) => {
              if (
                new Date(item.dob).getMonth() == date.getMonth() &&
                new Date(item.dob).getDate() == date.getDate()
              ) {
                return item;
              }
            });
            setEmployeList(emp);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-4">
      <div className="card member-panel">
        <div className="card-header bg-white">
          <h4 className="card-title mb-0">Upcoming Birthday</h4>
        </div>
        <div
          className={`card-body card-hights ${
            employeList.length <= 0 &&
            "d-flex align-items-center justify-content-center"
          }`}
        >
          {employeList.length > 0 ? (
            employeList.map((item: any) => {
              return (
                <ul className="contact-list" key={item.id}>
                  <li>
                    <div className="contact-cont">
                      <div className="float-left user-img m-r-10">
                        <img
                          src={
                            item.image
                              ? item.image
                              : require("../../../assets/nouser.jfif")
                          }
                          onError={(e: any) =>
                            imageOnErrorHandler(
                              e,
                              // eslint-disable-next-line @typescript-eslint/no-var-requires
                              require("../../../assets/nouser.jfif")
                            )
                          }
                          alt=""
                          className="w-40 rounded-circle br-20"
                        />
                      </div>
                      <div className="contact-info">
                        <span className="float-right">
                          {Moment(item.dob).format("MMMM-DD")}
                        </span>
                        <span className="contact-name text-ellipsis">
                          {`${item.first_name} ${item.last_name}`}{" "}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              );
            })
          ) : (
            <h3 className="d-flex justify-content-center">No data found!</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpComingBirthday;
