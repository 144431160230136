import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaEllipsisV, FaPencilRuler, FaEye } from "react-icons/fa";

import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";

import { imageOnErrorHandler } from "../../../Utilities/Helper";
type EmployeeTableProps = {
  employeList: any;
  setId: any;
  deleteEmployee: any;
  getEmployee: any;
};

const EmployeeTable: React.FC<EmployeeTableProps> = ({
  employeList,
  setId,
  deleteEmployee,
  getEmployee,
}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [emp_id, setEmp_id] = useState(0);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const role = localStorage.getItem("role");

  const editeEmploye = (id: any) => {
    localStorage.setItem("employee_id", id);
    navigate("/addemployee");
  };
  const viewEmploye = (id: any) => {
    localStorage.setItem("employee_id", id);
    localStorage.setItem("from", "employeeslist");
    navigate("/profile");
  };
  const setWorkProgress = (id: number) => {
    localStorage.setItem("wp_user_Id", id.toString());
    localStorage.setItem("from", "employeeslist");
    navigate("/singleprogress");
  };
  const checkInOut = (value: string, id: number) => {
    const params = {
      type: value,
      emp_id: id,
    };
    setEmp_id(id);
    instance({
      url: APILINK.attendance_insert,
      method: "POST",
      data: params,
      headers: {
        token: token,
      },
    })
      .then(async (response: any) => {
        if (response.data.status == 200) {
          getEmployee();
          if (value == "in") {
            setSuccess(true);
            setErr(false);
          } else {
            setErr(true);
            setSuccess(false);
          }
        }
      })
      .catch((err: any) => {
        console.log(err, "err");
      });
  };

  setTimeout(() => {
    setEmp_id(0);
    setSuccess(false);
    setErr(false);
  }, 10000);

  return (
    <div>
      <div className="row doctor-grid">
        {employeList.length > 0 &&
          employeList?.map((emp: any) => (
            <div className="col-md-4 col-sm-4 col-lg-3" key={emp.id}>
              <div className="profile-widget ">
                <div
                  className="doctor-img"
                  onClick={() => setWorkProgress(emp.id)}
                >
                  <img
                    className="w-100 h-100"
                    src={
                      emp.image
                        ? emp.image
                        : require("../../../assets/nouser.jfif")
                    }
                    onError={(e: any) =>
                      imageOnErrorHandler(
                        e,
                        // eslint-disable-next-line @typescript-eslint/no-var-requires
                        require("../../../assets/nouser.jfif")
                      )
                    }
                    alt=""
                  />
                </div>
                {role == "1" && (
                  <div className="dropdown profile-action">
                    <a
                      href="#"
                      className="action-icon dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FaEllipsisV />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a
                        className="dropdown-item"
                        onClick={() => viewEmploye(emp.id)}
                      >
                        <FaEye /> View
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => editeEmploye(emp.id)}
                      >
                        <FaPencilRuler /> Edit
                      </a>
                      <a
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#delete_expense"
                        onClick={() => setId(emp.id)}
                      >
                        <FaTrash /> Delete
                      </a>
                    </div>
                  </div>
                )}
                <h4 className="doctor-name text-ellipsis">
                  <a>{`${emp.first_name} ${emp.last_name}`}</a>
                </h4>
                <div className="doc-prof">{emp.role}</div>
                <div className="doc-prof">{emp.email}</div>
                <div className="user-country">
                  <i className="fa fa-map-marker"></i> {emp.mobile}
                </div>
                {success && emp_id == emp.id && (
                  <div className="alert alert-success" role="alert">
                    Sussessfly checked in
                  </div>
                )}
                {err && emp_id == emp.id && (
                  <div className="alert alert-danger" role="alert">
                    Sussessfly checked out
                  </div>
                )}
                {role == "1" && (
                  <div className=" mt-3 d-flex justify-content-between align-items-center">
                    <input
                      type="button"
                      disabled={emp.in_mode == 1 ? true : false}
                      onClick={() => checkInOut("in", emp.id)}
                      className={`btn ${
                        emp.in_mode == 0 ? "btn-success" : "alert-success"
                      }`}
                      value="Check In"
                    />

                    <input
                      type="button"
                      disabled={emp.out_mode == 1 ? true : false}
                      onClick={() => checkInOut("out", emp.id)}
                      className={`btn ${
                        emp.out_mode == 0 ? "btn-danger" : "alert-danger"
                      }`}
                      value="Check Out"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      <div
        id="delete_expense"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img
                src={require("../../../assets/img/sent.png")}
                alt=""
                width="50"
                height="46"
              />
              <h3>Are you sure want to delete this Employee?</h3>
              <div className="m-t-20 d-flex justify-content-around">
                <a href="#" className="btn btn-white" data-dismiss="modal">
                  Close
                </a>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={deleteEmployee}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTable;
