import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import Moment from "moment";

// type Props = {}

const UpcomingHolidays = () => {
  const [holidays, setHolidays] = useState([]);
  const token = localStorage.getItem("token");
  const date = new Date();
  //get api function
  const getHolidays = async () => {
    //Get API call for listing holidays
    await instance({
      url: APILINK.holidays,
      method: "GET",
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.data.status != 401) {
        setHolidays(response.data.data);
      } else if (parseInt(response.data.status) === 401) {
        window.location.href = "/";
      }
    });
  };
  const navigation = () => {
    localStorage.setItem("id", "5");
  };

  useEffect(() => {
    getHolidays();
  }, []);
  return (
    <div className="col-12 col-md-6 col-lg-8 col-xl-8">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title d-inline-block">Upcoming Holidays</h4>
          <Link
            to={"/holidays"}
            className="btn btn-primary float-right"
            onClick={navigation}
          >
            View all
          </Link>
        </div>
        <div className="card-body p-0 card-hights">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead className="d-none">
                <tr>
                  <th>Holiday</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {holidays.length > 0 ? (
                  holidays.map((item: any) => {
                    return (
                      new Date(item.date).getTime() >= date.getTime() && (
                        <tr key={item.id}>
                          <td>{item.holiday}</td>
                          <td>
                            <span className="float-right">
                              {Moment(item.date).format("DD/MM/YYYY")}
                            </span>
                          </td>
                        </tr>
                      )
                    );
                  })
                ) : (
                  <h3 className="d-flex justify-content-center">
                    No data found!
                  </h3>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingHolidays;
