import moment from "moment";
import React, { useEffect, useState } from "react";
import instance from "../../API/apiCall";
import EmployeeLeaves from "../components/customs/EmployeeLeaves";
import { Leave, LeavesTable } from "../components/customs/Leaves";
import Layouts from "../layouts/Layouts";
import { APILINK } from "../Utilities/ApiLink";

// type Props = {};

const SingleLeave = () => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("employee_id");

  const [leaveDays, setLeaveDays] = useState([]);
  const [empLeaveType, setEmpLeaveType] = useState([]);
  const [leaveCount, setLeaveCount] = useState([]);
  const [employeListDropDown, setEmployeListDropDown] = useState([
    {
      label: "Select Employee",
      value: "",
      hidden: true,
    },
  ]);
  const leaveList = async () => {
    const param = {
      emp_id: userId,
    };

    await instance({
      // url of the api endpoint (can be changed)
      url: APILINK.leave_list,
      method: "GET",
      params: param,
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        if (response.data.status !== 401) {
          setLeaveDays(response.data.data);
          setLeaveCount(response.data.leave_count);
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      }
    });
  };

  const employeeLeaveType = async () => {
    await instance({
      url: APILINK.leave_type,
      method: "GET",
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        if (response.data.status !== 400) {
          const empArray: any = [];
          empArray.push({
            label: "Leave Type",
            value: "",
            days: "",
            hidden: true,
          });
          response.data.data.map((item: any) => {
            const newEmpArray = {
              label: item.leave_type,
              value: item.id,
              days: item.total_days,
            };
            empArray.push(newEmpArray);
            setEmpLeaveType(empArray);
          });
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      }
    });
  };
  const getEmployee = () => {
    instance({
      url: APILINK.employee_get,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status != 401) {
            const responseData = response.data.data;
            const oldEmployee: any = [];
            oldEmployee.push({
              label: "Select Employee",
              value: "",
              hidden: true,
            });
            responseData?.map((emp: any) => {
              const employee = {
                label: `${emp.first_name} ${emp.last_name}`,
                value: emp.id,
              };
              oldEmployee.push(employee);
              setEmployeListDropDown(oldEmployee);
            });
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  useEffect(() => {
    leaveList();
    employeeLeaveType();
    getEmployee();
  }, []);
  return (
    <div className="content fc">
      <EmployeeLeaves />

      {leaveDays.length > 0 ? (
        <LeavesTable leaveDays={leaveDays} leaveList={leaveList} />
      ) : (
        <h3 className="d-flex justify-content-center">No data found!</h3>
      )}
    </div>
  );
};

export default SingleLeave;
