/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-empty */
import React, { useEffect, useState } from "react";
import Layouts from "../../../layouts/Layouts";
import { Formik } from "formik";
import * as Yup from "yup";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { BsCaretDownFill } from "react-icons/bs";
import Dropdown from "../Dropdown";
import Button from "../../basics/button/Button";

const defaultImage = require("../../../assets/img/no-user.jpg");

// type Props = {}

const AddEmployee = () => {
  const navigate = useNavigate();

  const [singObj, setSingObj] = useState({
    email: "",
    emp_id: "",
    first_name: "",
    last_name: "",
    dob: "",
    doj: "",
    mobile: "",
    designation: "",
    image: "",
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    branch_name: "",
    reason: "",
    dol: "",
    address: "",
  });

  const token = localStorage.getItem("token");
  const employeeId = localStorage.getItem("employee_id");
  const [role, setRole] = useState([]);
  const [image, setImage] = useState("");
  const [check, setCheck] = useState(false);

  const EmployeeSchema = Yup.object().shape({
    emp_id: Yup.string().required("Employee Id is required"),
    email: Yup.string().email("Email not valid").required("Email is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    dob: Yup.string().required("Date of birth is required"),
    doj: Yup.string().required("Date of joining is required"),
    mobile: Yup.string().required("Mobile number is required"),
    designation: Yup.string().required("Designation is required"),
    bank_name: Yup.string().required("Bank name is required"),
    account_number: Yup.string().required("Account number is required"),
    ifsc_code: Yup.string().required("IFSC code is required"),
    branch_name: Yup.string().required("Branch Name is required"),

    address: Yup.string().required("Address is required"),
  });
  const EmployeeEditSchema = Yup.object().shape({
    emp_id: Yup.string().required("Employee Id is required"),
    email: Yup.string().email("Email not valid").required("Email is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    dob: Yup.string().required("Date of birth is required"),
    doj: Yup.string().required("Date of joining is required"),
    mobile: Yup.string().required("Mobile number is required"),
    designation: Yup.string().required("Designation is required"),
    bank_name: Yup.string().required("Bank name is required"),
    account_number: Yup.string().required("Account number is required"),
    ifsc_code: Yup.string().required("IFSC code is required"),
    branch_name: Yup.string().required("Branch Name is required"),
    dol: Yup.string().required("Date is required"),
    reason: Yup.string().required("Reason is required"),
    address: Yup.string().required("Address is required"),
  });

  const imageHandler = (e: any) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // get Role
  const getRole = () => {
    instance({
      url: APILINK.role,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;
          const oldRole: any = [];
          oldRole.push({
            label: "Select Role",
            value: "",
            hidden: true,
          });
          responseData.map((rol: any) => {
            const role = {
              label: rol.role,
              value: rol.id,
            };
            oldRole.push(role);
            setRole(oldRole);
          });
        } else if (response.data.status == 401) {
          window.location.href = "/";
        } else {
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // get getSingleEmploye
  const getSingleEmploye = () => {
    const params = {
      id: employeeId,
    };
    instance({
      url: APILINK.employee_get,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data[0];
          if (response.data.data.length > 0) {
            setSingObj({
              ...responseData,
              dob: Moment(responseData.dob).format("YYYY-MM-DD"),
              doj: Moment(responseData.doj).format("YYYY-MM-DD"),
              designation: responseData.role_id,
            });
            setImage(responseData.image);
          }
        } else if (response.status == 401) {
          window.location.href = "/";
        } else {
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const checkBox = (e: any) => {
    if (e.target.checked) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };

  const onSubmit = (values: any) => {
    const params = {
      ...values,
      designation: parseInt(values.designation),
      image: image,
    };

    instance({
      url: employeeId
        ? `${APILINK.employee_get}/id=${employeeId}`
        : APILINK.employee_add,
      method: employeeId ? "PUT" : "POST",
      data: employeeId ? { ...params, id: employeeId } : params,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          navigate("/profile");
          localStorage.setItem("id", "");
        } else if (response.status == 401) {
          window.location.href = "/";
        } else {
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  useEffect(() => {
    getRole();
    employeeId && getSingleEmploye();
  }, []);
  return (
    <Layouts>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-sm-12">
              <h4 className="page-title">
                {employeeId ? "Edit Profile" : "Add Employee"}
              </h4>
            </div>
          </div>
          <Formik
            initialValues={singObj}
            onSubmit={(values: any) => onSubmit(values)}
            validationSchema={
              employeeId && check === true ? EmployeeEditSchema : EmployeeSchema
            }
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <div className="card-box">
                  <h3 className="card-title">Basic Informations</h3>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-img-wrap">
                        <img
                          className="inline-block"
                          src={image ? image : defaultImage}
                          alt="user"
                        />
                        <div className="fileupload btn">
                          <span className="btn-text">edit</span>
                          <input
                            className="upload"
                            type="file"
                            onChange={(e) => imageHandler(e)}
                          />
                        </div>
                      </div>
                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group  focused">
                              <label>
                                First Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control floating"
                                placeholder="First Name"
                                onChange={handleChange("first_name")}
                                onBlur={handleBlur("first_name")}
                                value={values.first_name}
                              />
                              {errors.first_name && touched.first_name && (
                                <p className="text-danger">
                                  {errors.first_name}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group  focused">
                              <label>
                                Last Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control floating"
                                placeholder="Last Name"
                                onChange={handleChange("last_name")}
                                onBlur={handleBlur("last_name")}
                                value={values.last_name}
                              />
                              {errors.last_name && touched.last_name && (
                                <p className="text-danger">
                                  {errors.last_name}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group  focused">
                              <div className="">
                                <div className="react-datepicker-wrapper">
                                  <div className="react-datepicker__input-container">
                                    <label>
                                      Birth Date
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control datetimepicker"
                                      placeholder="Birth Date"
                                      onChange={handleChange("dob")}
                                      onBlur={handleBlur("dob")}
                                      value={values.dob}
                                      max={Moment(new Date()).format(
                                        "YYYY-MM-DD"
                                      )}
                                    />
                                    {errors.dob && touched.dob && (
                                      <p className="text-danger">
                                        {errors.dob}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <label>
                                Joining Date
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control floating"
                                placeholder="Joining Date"
                                onChange={handleChange("doj")}
                                onBlur={handleBlur("doj")}
                                value={values.doj}
                                max={Moment(new Date()).format("YYYY-MM-DD")}
                              />
                              {errors.doj && touched.doj && (
                                <p className="text-danger">{errors.doj}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group  focused">
                          <label>
                            Email
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control floating"
                            placeholder="Email"
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            value={values.email}
                          />
                          {errors.email && touched.email && (
                            <p className="text-danger">{errors.email}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group  focused">
                          <label>
                            Employee Id
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control floating"
                            placeholder="Employee Id"
                            onChange={handleChange("emp_id")}
                            onBlur={handleBlur("emp_id")}
                            value={values.emp_id}
                          />
                          {errors.emp_id && touched.emp_id && (
                            <p className="text-danger">{errors.emp_id}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group focused">
                          <label>
                            Phone Number
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control floating"
                            placeholder="Phone Number"
                            onChange={handleChange("mobile")}
                            onBlur={handleBlur("mobile")}
                            value={values.mobile}
                          />
                          {errors.mobile && touched.mobile && (
                            <p className="text-danger">{errors.mobile}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-6 position-relative select floating">
                        <label>
                          Designation
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="select floating icons w-100 form-control "
                          onChange={handleChange("designation")}
                          onBlur={handleBlur("designation")}
                          value={values.designation}
                          placeholder="Phone Number"
                        >
                          {role?.map((item: any) => {
                            return (
                              <>
                                <option
                                  key={item.id}
                                  hidden={item.hidden}
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        <BsCaretDownFill className="search-icon position-absolute" />

                        {errors.designation && touched.designation && (
                          <p className="text-danger">{errors.designation}</p>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group  focused">
                          <label>
                            Address
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control floating"
                            placeholder="Address"
                            onChange={handleChange("address")}
                            onBlur={handleBlur("address")}
                            value={values.address}
                          />
                          {errors.address && touched.address && (
                            <p className="text-danger">{errors.address}</p>
                          )}
                        </div>
                      </div>

                      <div className="form-group col-md-12 mb-0">
                        <h3 className="card-title">Bank Details</h3>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group  focused">
                          <label>
                            Bank Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control floating"
                            placeholder="Bank Name"
                            onChange={handleChange("bank_name")}
                            onBlur={handleBlur("bank_name")}
                            value={values.bank_name}
                          />
                          {errors.bank_name && touched.bank_name && (
                            <p className="text-danger">{errors.bank_name}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group  focused">
                          <label>
                            Account Number
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control floating"
                            placeholder="Account Number"
                            onChange={handleChange("account_number")}
                            onBlur={handleBlur("account_number")}
                            value={values.account_number}
                          />
                          {errors.account_number && touched.account_number && (
                            <p className="text-danger">
                              {errors.account_number}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group  focused">
                          <label>
                            IFSC Code
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control floating"
                            placeholder="IFSC code"
                            onChange={handleChange("ifsc_code")}
                            onBlur={handleBlur("ifsc_code")}
                            value={values.ifsc_code}
                          />
                          {errors.ifsc_code && touched.ifsc_code && (
                            <p className="text-danger">{errors.ifsc_code}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group  focused">
                          <label>
                            Branch Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control floating"
                            placeholder="Branch Name"
                            onChange={handleChange("branch_name")}
                            onBlur={handleBlur("branch_name")}
                            value={values.branch_name}
                          />
                          {errors.branch_name && touched.branch_name && (
                            <p className="text-danger">{errors.branch_name}</p>
                          )}
                        </div>
                      </div>
                      {employeeId && (
                        <div className="col-md-12">
                          <div className="form-group  focused d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="mr-2"
                              placeholder=""
                              onChange={(e) => {
                                checkBox(e);
                              }}
                            />
                            <p className="mb-0"> Relieving the office</p>
                          </div>
                        </div>
                      )}
                      {check === true && (
                        <>
                          <div className="col-md-6">
                            <div className="form-group  focused">
                              <label>
                                Date
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control floating"
                                placeholder="Date"
                                onChange={handleChange("dol")}
                                onBlur={handleBlur("dol")}
                                value={values.dol}
                              />
                              {errors.dol && touched.dol && (
                                <p className="text-danger">{errors.dol}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group  focused">
                              <label>
                                Reason
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control floating"
                                placeholder="Reason"
                                onChange={handleChange("reason")}
                                onBlur={handleBlur("reason")}
                                value={values.reason}
                              />
                              {errors.reason && touched.reason && (
                                <p className="text-danger">{errors.reason}</p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="text-center m-t-20">
                  {/* component create for button at 03/03/23  */}
                  <Button
                    type="submit"
                    text="Save"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary submit-btn"
                  />
                  {/* <button
                    type="button"
                    className="btn btn-primary submit-btn"
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </button> */}
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </Layouts>
  );
};

export default AddEmployee;
