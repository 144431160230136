import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import instance from "../../API/apiCall";
import Layouts from "../layouts/Layouts";
import { APILINK } from "../Utilities/ApiLink";
import Moment from "moment";
import { imageOnErrorHandler } from "../Utilities/Helper";
import Calendar from "../components/customs/Calendar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { SingleLeave } from "../pages";
// type Props = {}

const Profile = () => {
  const navigate = useNavigate();
  const employeeId: any = localStorage.getItem("employee_id");
  const from: any = localStorage.getItem("from");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const [singObj, setSingObj] = useState({
    email: "",
    emp_id: "",
    first_name: "",
    last_name: "",
    dob: "",
    doj: "",
    mobile: "",
    designation: "",
    image: "",
    role: "",
  });

  const [tab, setTab] = useState([
    {
      id: 1,
      key: "calendar",
      name: "Calendar",
    },
    role == "1" && {
      id: 2,
      key: "leave-info",
      name: "Leave Info",
    },
  ]);

  // for calendar view
  const [years, setYears] = useState(Moment(new Date()).format("YYYY-MM"));
  const [search, setSearch] = useState([]);
  const [dates, setDates] = useState([{}]);
  const editeEmploye = () => {
    localStorage.setItem("employee_id", employeeId.toString());
    navigate("/addemployee");
  };

  // get getSingleEmploye
  const getSingleEmploye = () => {
    const params = {
      id: employeeId,
    };
    instance({
      url: APILINK.employee_get,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data[0];
          if (response.data.data.length > 0) {
            setSingObj({
              ...responseData,
              dob: Moment(responseData.dob).format("YYYY-MM-DD"),
              doj: Moment(responseData.doj).format("YYYY-MM-DD"),
              designation: responseData.role_id,
            });
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // for calendar view
  const getYear = () => {
    const params = {
      year: Moment(years).format("YYYY"),

      month: Moment(years).format("MM"),
      /*  year: "2022",
      month: "11", */
    };
    instance({
      url: APILINK.attendance_list,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          setSearch(response.data.data);
          setDates(Object.keys(response.data.data[0]));
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    employeeId && getSingleEmploye();
    getYear();
  }, []);
  return (
    <Layouts>
      <div className="page-wrapper">
        <div className="content ">
          <div className="row ">
            <div className="col-sm-7 col-6">
              <h4 className="page-title">
                {from == "profile"
                  ? "My Profile"
                  : `Profile - ${singObj.first_name} ${singObj.last_name}`}
              </h4>
            </div>

            {from == "profile" && (
              <div className="col-sm-5 col-6 text-right m-b-30">
                <div
                  onClick={editeEmploye}
                  className="btn btn-primary "
                  style={{ borderRadius: "5px" }}
                >
                  <i className="fa fa-plus"></i> Edit Profile
                </div>
              </div>
            )}
          </div>
          <div className="card-box profile-header">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="profile-view">
                  <div className="profile-img-wrap">
                    <div className="profile-img">
                      <img
                        className="rounded-circle" /* 
                          src="assets/img/doctor-03.jpg" */
                        src={
                          singObj.image
                            ? singObj.image
                            : require("../assets/nouser.jfif")
                        }
                        alt=""
                        onError={(e: any) =>
                          imageOnErrorHandler(
                            e,
                            // eslint-disable-next-line @typescript-eslint/no-var-requires
                            require("../assets/nouser.jfif")
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="profile-basic">
                    <div className="row align-items-center">
                      <div className="col-md-5">
                        <div className="profile-info-left">
                          <h3 className="user-name m-t-0 mb-0">
                            {`${singObj.first_name} ${singObj.last_name}`}
                          </h3>
                          <small className="text-muted">{singObj.role}</small>
                          <div className="staff-id">
                            Employee ID : {singObj.emp_id}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <ul className="personal-info">
                          <li>
                            <span className="title">Phone:</span>
                            <span className="text">
                              <a href="">{singObj.mobile}</a>
                            </span>
                          </li>
                          <li>
                            <span className="title">Email:</span>
                            <span className="text">
                              <a href="">
                                <span>{singObj.email}</span>
                              </a>
                            </span>
                          </li>
                          <li>
                            <span className="title">Birthday:</span>
                            <span className="text">{singObj.dob}</span>
                          </li>
                          <li>
                            <span className="title">Date of join:</span>
                            <span className="text">{singObj.doj}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Tab.Container id="left-tabs-example" defaultActiveKey="calendar">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills">
                    {tab.map((item: any) => {
                      return (
                        <Nav.Item key={item.id}>
                          <Nav.Link className="cu" eventKey={item.key}>
                            {item.name}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="calendar">
                      <Calendar
                        dates={dates}
                        years={years}
                        setYears={setYears}
                        getYear={getYear}
                        search={search}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="leave-info">
                      <SingleLeave />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default Profile;
