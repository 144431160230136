import React from "react";
import { Link } from "react-router-dom";
import Button from "../../basics/button/Button";

// type Props = {}

const RegisterContainer = () => {
  return (
    <div className="account-wrapper">
      <div className="account-center">
        <div className="account-box">
          <form action="/react/template/dashboard" className="form-signin">
            <div className="account-logo">
              <Link to={"/dashboard"}>
                <img src={require("../../../assets/img/ew.png")} alt="" />
              </Link>
            </div>
            <div className="form-group">
              <label>Username</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group">
              <label>Email Address</label>
              <input type="email" className="form-control" />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input type="password" className="form-control" />
            </div>
            <div className="form-group">
              <label>Mobile Number</label>
              <input type="text" className="form-control" />
            </div>
            <div className="form-group checkbox">
              <label>
                
                <input type="checkbox" /> I have read and agree the Terms &amp;
                Conditions
              </label>
            </div>
            <div className="form-group text-center">


              <Button className="btn btn-primary account-btn" type="submit" text="Signup"/>

              
            </div>
            <div className="text-center login-link">
              Already have an account?
              <Link to={"/"}>Login</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterContainer;
