import React, { useState } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

type EmployeeSalaryHeaderProps = { employeFilter?: any };

const EmployeeSalaryHeader: React.FC<EmployeeSalaryHeaderProps> = ({
  employeFilter,
}) => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([
    {
      id: 1,
      name: "Select Status",
      hidden: true,
    },
    {
      id: 2,
      name: "Pending",
      hidden: false,
    },
    {
      id: 3,
      name: "Approved",
      hidden: false,
    },
    {
      id: 4,
      name: "REjected",
      hidden: false,
    },
  ]);
  const [role, setRole] = useState([
    {
      id: 1,
      name: "Select Role",
      hidden: true,
    },
    {
      id: 2,
      name: "Employee",
      hidden: false,
    },
    {
      id: 3,
      name: "Manager",
      hidden: false,
    },
  ]);
  return (
    <>
      <div className="row">
        <div className="col-sm-4 col-5">
          <h4 className="page-title">Employee Salary</h4>
        </div>
        {/*  <div className="col-sm-8 col-7 text-right m-b-30">
          <Link
            
            to={{
              pathname: `/addemployesalary`,
              search: `?backUrl=${"backUrl"}`,
            }}
            className="btn btn-primary btn-rounded float-right"
          >
            <FaPlus /> Add Salary
          </Link>
        </div> */}
      </div>
      <div className="row filter-row form-group">
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-6 col-6">
          <div className=" ">
            <input
              type="text"
              placeholder="Employee Name"
              className="form-control floating"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        {/*  <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="  select-focus">
            <select className="select form-control floating w-100">
              {role?.map((items: any) => (
                <option key={items.id} hidden={items.hidden}>
                  {items.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="  select-focus">
            <select className="select form-control floating w-100">
              {status?.map((items: any) => (
                <option key={items.id} hidden={items.hidden}>
                  {items.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className=" ">
            <input
              className="form-control "
              type="date"
              value={Moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className=" ">
            <input
              className="form-control "
              type="date"
              value={Moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </div> */}

        <div className="col-sm-6 col-md-3 col-lg-3 col-xl- col-6">
          <input
            type="button"
            className="btn btn-success btn-block"
            value={"Search"}
            onClick={() => employeFilter(search)}
          />
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-6">
          <a
            className="btn btn-warning btn-block"
            onClick={() => {
              employeFilter(""), setSearch("");
            }}
          >
            Clear
          </a>
        </div>
      </div>
    </>
  );
};

export default EmployeeSalaryHeader;
