import React, { useEffect, useState } from "react";
import {
  FaDashcube,
  FaUser,
  FaMoneyBillAlt,
  FaMoneyBillWave,
  FaCalendarPlus,
  FaClipboard,
  FaSignOutAlt,
  FaProjectDiagram,
} from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import { Link } from "react-router-dom";

type SideBarProps = {
  showSideBar?: boolean;
  children?: JSX.Element | JSX.Element[];
};

const SideBar: React.FC<SideBarProps> = ({ showSideBar }) => {
  const role = localStorage.getItem("role");

  const [id, setId] = useState(
    localStorage.getItem("id") ? localStorage.getItem("id") : 1
  );
  const [subId, setSubId] = useState(
    localStorage.getItem("id") ? localStorage.getItem("subId") : 0
  );
  const setabId = (id: number, tag: string) => {
    if (tag == "parent") {
      setId(id);
      setSubId(0);
      localStorage.setItem("subId", "0");
      localStorage.setItem("id", id.toString());
    } else {
      setSubId(id);
      localStorage.setItem("subId", id.toString());
    }
  };
  const [sideBar, setSideBar] = useState([]);

  const sidemenu: any = [
    {
      id: 1,
      name: "Dashboard",
      href: "/dashboard",
      icon: FaDashcube,
    },
    /* role == "1" &&  */ {
      id: 2,
      name: "Employees",
      href: "/employeeslist",
      icon: FaUser,
    },
    {
      id: 3,
      name: "Work Progress",
      href: "/workprogress",
      icon: GiProgression,
    },
    {
      id: 4,
      name: "Leaves",
      href: "/leaves",
      icon: FaSignOutAlt,
    },
    {
      id: 5,
      name: "Holidays",
      href: "/holidays",
      icon: FaCalendarPlus,
    },
    {
      id: 6,
      name: "Attendance",
      href: "/attendance",
      icon: FaClipboard,
    },
    /* role == "1" &&  */ {
      id: 7,
      name: "Expenses",
      href: "/expenses",
      icon: FaMoneyBillAlt,

      /*  {
              id: 1,
              subName: "Employee Salary",
              href: "/employeesalary",
            },
            {
              id: 2,
              subName: "Payslip",
              href: "/payslip",
            }, */
    },
    /*   {
      id: 8,
      name: "Payslip",
      href: "/payslip",
      icon: FaMoneyBillAlt,
    }, */
    {
      id: 8,
      name: "Employee Salary",
      href: "/employeesalary",
      icon: FaMoneyBillWave,
    },
  ];

  const sidebars: any = [
    {
      id: 1,
      name: "Dashboard",
      href: "/dashboard",
      icon: FaDashcube,
    },
    {
      id: 3,
      name: "Work Progress",
      href: "/workprogress",
      icon: GiProgression,
    },
    /*   {
      id: 4,
      name: "Project",
      href: "/project",
      icon: FaProjectDiagram,
    }, */
    {
      id: 5,
      name: "Leaves",
      href: "/leaves",
      icon: FaSignOutAlt,
    },
    {
      id: 6,
      name: "Holidays",
      href: "/holidays",
      icon: FaCalendarPlus,
    },
    {
      id: 7,
      name: "Attendance",
      href: "/attendance",
      icon: FaClipboard,
    },
  ];

  useEffect(() => {
    setSideBar(role == "1" ? sidemenu : sidebars);
  }, []);

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            {sideBar?.map((Menu: any) => {
              return (
                <>
                  <li
                    className={`overflow-hidden ${
                      id == Menu.id ? "active" : ""
                    }`}
                  >
                    <Link
                      to={Menu.href}
                      className={id == Menu.id ? "subdrop " : ""}
                      onClick={() => setabId(Menu.id, "parent")}
                    >
                      {Menu.icon && <Menu.icon />}
                      <span className={id === Menu.id ? "subdrop " : " "}>
                        {Menu.name}
                      </span>
                    </Link>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
