import React from "react";
import Projects from "../components/customs/Projectpage/Projects";
import Layouts from "../layouts/Layouts";

const ProjectAdd = () => {
  return (
    <div>
      <Layouts>
        <div className="page-wrapper">
          <div className="content">
            <Projects />
          </div>
        </div>
      </Layouts>
    </div>
  );
};

export default ProjectAdd;
