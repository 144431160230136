import React from "react";
import Layouts from "../layouts/Layouts";
import { DashboardContent } from "../components/customs/Dashboard";

type DashboardProps = { title?: any };

const Dashboard: React.FC<DashboardProps> = () => {
  return (
    <>
      <Layouts>
        <DashboardContent />
      </Layouts>
    </>
  );
};

export default Dashboard;
