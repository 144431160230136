import React, { useEffect, useState } from "react";
import {
  FaEllipsisV,
  FaPencilRuler,
  FaDownload,
  FaTrash,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import Moment from "moment";
import { values } from "lodash";

type ExpensesTableProps = { expensesList: any; expense: any };

const ExpensesTable: React.FC<ExpensesTableProps> = ({
  expensesList,
  expense,
}) => {
  const navigate = useNavigate();

  const [status, setStatus] = useState([
    {
      id: 1,
      name: "Pending",
    },
    {
      id: 2,
      name: "Approved",
    },
  ]);

  const [id, setId] = useState();

  // local storage value
  const token = localStorage.getItem("token");
  const Role = localStorage.getItem("role");

  // Edit function

  const editExpenses = (id: any) => {
    localStorage.setItem("expense_Id", id);
    navigate("/addexpenses");
  };

  // Delete expenses api call
  const deleteExpenses = () => {
    const params = {
      id: id,
    };
    instance({
      url: `${APILINK.expenses}/id=${id}`,
      method: "DELETE",
      data: params,
      headers: {
        token: token,
      },
    })
      .then(async (response: { status: number; data: { status: number } }) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            expensesList();
          } else if (response.data.status === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err: any) => {
        console.log(err, "err");
      });
  };

  // change expense status api call

  const expenseStatus = (id: number, status: string) => {
    const params = {
      id: id,
      role: "admin",
      status: status,
    };
    instance({
      url: `${APILINK.expenses}/id=${id}`,
      method: "PUT",
      data: params,
      headers: {
        token: token,
      },
    })
      .then((_response: any) => {
        expensesList();
      })
      .catch((err: any) => {
        console.log(err, "err");
      });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-striped custom-table mb-0 datatable">
            <thead>
              <tr>
                <th>Item</th>
                <th>Purchase From</th>
                <th>Purchase Date</th>
                <th>Purchased By</th>
                <th>Amount</th>
                <th>Paid By</th>
                <th className="text-center">Status</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {expense?.map((items: any) => {
                return (
                  <tr key={items.id}>
                    <td>
                      <strong>{items.item_name}</strong>
                    </td>
                    <td>{items.purchase_from}</td>
                    <td>{`${Moment(items.purchase_date).format(
                      /* "YYYY-MM-DD" */ "DD-MM-YYYY"
                    )}`}</td>
                    <td>{items.first_name}</td>
                    <td>{items.amount}</td>
                    <td>{items.pay_type}</td>
                    <td className="text-center ">
                      <div className="dropdown  action-label">
                        <a
                          className={`custom-badge  dropdown-toggle ${
                            items.status == "Pending"
                              ? "status-red"
                              : "status-green"
                          }`}
                          style={{ paddingTop: "5px", paddingBottom: "5px" }}
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {items.status}
                        </a>
                        {Role == "0" ? (
                          ""
                        ) : (
                          <div className="dropdown-menu dropdown-menu-right">
                            {status?.map((item: any) => (
                              <a
                                key={item.id}
                                className="dropdown-item"
                                onClick={() => {
                                  expenseStatus(items.id, item.name);
                                }}
                              >
                                {item.name}
                              </a>
                            ))}
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="text-right">
                      <div className="dropdown dropdown-action">
                        <a
                          href="#"
                          className="action-icon dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FaEllipsisV />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <a
                            className="dropdown-item"
                            onClick={() => editExpenses(items.id)}
                          >
                            <FaPencilRuler /> Edit
                          </a>

                          <a
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#delete_expense"
                            onClick={() => setId(items.id)}
                          >
                            <FaTrash /> Delete
                          </a>
                          <a
                            href={items && items.attachments}
                            className="dropdown-item"
                            download
                          >
                            <FaDownload /> Download
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        id="delete_expense"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img
                src={require("../../../assets/img/sent.png")}
                alt=""
                width="50"
                height="46"
              />
              <h3>Are you sure want to delete this expense?</h3>
              <div className="m-t-20 d-flex justify-content-around">
                <a href="#" className="btn btn-white" data-dismiss="modal">
                  Close
                </a>
                <button
                  type="submit"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={deleteExpenses}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpensesTable;
