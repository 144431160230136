/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
type WorkProgressTableProps = {
  workProgress: any;
  singleData: any;
};

const WorkProgressTable: React.FC<WorkProgressTableProps> = ({
  workProgress,
  singleData,
}) => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const setWorkProgress = (id: number) => {
    localStorage.setItem("wp_user_Id", id.toString());
    localStorage.setItem("from", "workprogress");
    navigate("/singleprogress");
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-striped custom-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Day</th>
                <th>In Time</th>
                <th>Out Time</th>
                <th>Hours Worked</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {workProgress &&
                workProgress?.map((item: any) => {
                  return (
                    <tr key={item?.emp_id}>
                      <td
                        onClick={() => setWorkProgress(item?.emp_id)}
                      >{`${item?.first_name} ${item?.last_name}`}</td>
                      <td>{Moment(item?.date).format("DD-MM-YYYY")}</td>
                      <td>
                        {role === "1" ? item?.day : item?.worked_hours?.day}
                      </td>
                      <td>
                        {role === "1"
                          ? item?.in_time
                          : item?.worked_hours?.in_time}
                      </td>
                      <td>
                        {role === "1"
                          ? item?.out_mode && item?.out_mode == 0
                            ? "-"
                            : item?.out_time
                          : item?.worked_hours?.out_mode &&
                            item?.worked_hours?.out_mode == 0
                          ? "-"
                          : item?.worked_hours?.out_time}
                      </td>
                      {(
                        role === "1"
                          ? item?.out_mode &&
                            item?.out_mode == 1 &&
                            item?.in_mode == 1
                          : item?.worked_hours?.out_mode &&
                            item?.worked_hours?.out_mode == 1 &&
                            item?.worked_hours.in_mode == 1
                      ) ? (
                        <td>
                          {role === "1"
                            ? `${Moment(
                                item.worked_hours,
                                "HH:mm:ss"
                              ).hours()} Hours ${Moment(
                                item.worked_hours,
                                "HH:mm:ss"
                              ).minutes()} Minutes`
                            : `${Moment(
                                item.worked_hours.worked_hours,
                                "HH:mm:ss"
                              ).hours()} Hours ${Moment(
                                item.worked_hours.worked_hours,
                                "HH:mm:ss"
                              ).minutes()} Minutes`}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="text-center">
                        <FaEye
                          onClick={() => setWorkProgress(item?.emp_id)}
                          className="view-icon"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WorkProgressTable;
