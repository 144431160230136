/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import Moment from "moment";
import { FaEllipsisV, FaPencilRuler, FaTrash } from "react-icons/fa";

type LeaveTableProps = { leaveDays: any; leaveList: any };

const LeavesTable: React.FC<LeaveTableProps> = ({ leaveDays, leaveList }) => {
  const navigate = useNavigate();

  const [status, setStatus] = useState([
    {
      id: 1,
      name: "Pending",
    },
    {
      id: 2,
      name: "Approved",
    },
    {
      id: 3,
      name: "Declined",
    },
  ]);

  const [id, setId] = useState();

  // local storage value

  const token = localStorage.getItem("token");
  const Role = localStorage.getItem("role");
  const userId = localStorage.getItem("user_Id");

  // Edit funtion

  const editLeave = (id: any) => {
    localStorage.setItem("leave_Id", id);
    navigate("/addleave");
  };

  // Delete leave api call

  const deleteLeave = () => {
    const params = {
      id: id,
    };
    instance({
      url: `${APILINK.leave_list}/id=${id}`,
      method: "DELETE",
      data: params,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            leaveList();
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // change status api call

  const leaveStatus = (id: number, status: string, leave_Type: string) => {
    const params = {
      id: id,
      role: "admin",
      leave_type: leave_Type,
      status: status,
    };

    instance({
      url: `${APILINK.leave_list}/id=${id}`,
      method: "PUT",
      data: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        leaveList();
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Leave Type</th>
                  <th>From</th>
                  <th>To</th>
                  <th>No of Days</th>
                  <th>Reason</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {leaveDays?.map((item: any) => (
                  <tr key={item.id}>
                    <td>
                      {/*   <Link to={"/profile"} className="avatar">
                        sd
                      </Link> */}
                      <h2>
                        <Link to={"#"}> {item.first_name}</Link>
                      </h2>
                    </td>
                    <td>{item.leave_type}</td>
                    <td>{`${Moment(item.from_date).format("YYYY-MM-DD")}`}</td>
                    <td>{`${Moment(item.to_date).format("YYYY-MM-DD")}`}</td>
                    <td>{item.no_of_days}</td>
                    <td className="w-25 p-3">{item.reason}</td>
                    <td className="text-center">
                      <div className="dropdown action-label">
                        <a
                          className={`custom-badge dropdown-toggle ${
                            item.status === "Pending"
                              ? "status-pink"
                              : item.status === "Approved"
                              ? "status-green"
                              : "status-red"
                          }`}
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {item.status}
                        </a>
                        {Role == "0" ? (
                          ""
                        ) : (
                          <div className="dropdown-menu dropdown-menu-right">
                            {status?.map((items: any) => (
                              <a
                                key={items.id}
                                className="dropdown-item"
                                onClick={() =>
                                  leaveStatus(
                                    item.id,
                                    items.name,
                                    item.leave_type
                                  )
                                }
                              >
                                {items.name}
                              </a>
                            ))}
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="text-center">
                      {userId == item.emp_id ? (
                        item.status != "Approved" ? (
                          <div className="dropdown dropdown-action">
                            <a
                              href="#"
                              className="action-icon dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <FaEllipsisV />
                            </a>

                            <div className="dropdown-menu dropdown-menu-right">
                              <a
                                className="dropdown-item"
                                onClick={() => editLeave(item.id)}
                              >
                                <FaPencilRuler /> Edit
                              </a>

                              <a
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="#delete_expense"
                                onClick={() => setId(item.id)}
                              >
                                <FaTrash /> Delete
                              </a>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          id="delete_expense"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={require("../../../assets/img/sent.png")}
                  alt=""
                  width="50"
                  height="46"
                />
                <h3>Are you sure want to delete this?</h3>
                <div className="m-t-20 d-flex justify-content-around">
                  <a href="#" className="btn btn-white" data-dismiss="modal">
                    Close
                  </a>

                  <button
                    type="submit"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={deleteLeave}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeavesTable;
