import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaEllipsisV, FaPencilRuler, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { imageOnErrorHandler } from "../../../Utilities/Helper";

type EmployeeSalaryTableProps = { employeList: any };

const EmployeeSalaryTable: React.FC<EmployeeSalaryTableProps> = ({
  employeList,
}) => {
  const [role, setrole] = useState([
    {
      id: 1,
      name: "Nurse",
    },
    {
      id: 2,
      name: "Laboratorist",
    },
    {
      id: 3,
      name: "Pharmacist",
    },
    {
      id: 4,
      name: "Accountant",
    },
    {
      id: 5,
      name: "Receptionist",
    },
  ]);
  const navigate = useNavigate();

  const paySlip = (id: any) => {
    localStorage.setItem("employee_id", id);
    navigate("/payslip");
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-striped custom-table datatable">
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Employee</th>
                <th>Email</th>
                <th>Joining Date</th>
                <th>Role</th>
                <th>Salary</th>
                <th>Payslip</th>
                {/* <th className="text-right">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {employeList?.map((items: any) => {
                return (
                  <tr key={items.id}>
                    <td>{items.emp_id}</td>
                    <td>
                      {/* <img
                        className="rounded-circle"
                        src={
                          items.image
                            ? items.image
                            : require("../../../assets/nouser.jfif")
                        }
                        onError={(e: any) =>
                          imageOnErrorHandler(
                            e,
                            // eslint-disable-next-line @typescript-eslint/no-var-requires
                            require("../../../assets/nouser.jfif")
                          )
                        }
                        height="28"
                        width="28"
                        alt=""
                      /> */}
                      {`${items.first_name} ${items.last_name}`}
                    </td>
                    <td>
                      <a
                        href={`mailto:${items.email}`}
                        className="__cf_email__"
                      >
                        {items.email}
                      </a>
                    </td>
                    <td>{moment(items.doj).format("DD-MM-YYYY")}</td>
                    <td>{items.role}</td>
                    <td>{items.salary ? items.salary : "-"}</td>
                    <td>
                      <div
                        className="btn btn-sm btn-primary"
                        onClick={() => paySlip(items.id)}
                      >
                        Generate Slip
                      </div>
                    </td>
                    {/*   <td className="text-right">
                      <div className="dropdown dropdown-action">
                        <a
                          href="#"
                          className="action-icon dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FaEllipsisV />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link
                            to={"/addemployesalary"}
                            className="dropdown-item"
                          >
                            <FaPencilRuler /> Edit
                          </Link>
                          <a
                            className="dropdown-item"
                            href="#"
                            data-toggle="modal"
                            data-target="#delete_expense"
                          >
                            <FaTrash /> Delete
                          </a>
                        </div>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        id="delete_expense"
        className="modal fade delete-modal"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img
                src={require("../../../assets/img/sent.png")}
                alt=""
                width="50"
                height="46"
              />
              <h3>Are you sure want to delete this Salary?</h3>
              <div className="m-t-20 d-flex justify-content-around">
                <a href="#" className="btn btn-white" data-dismiss="modal">
                  Close
                </a>
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeSalaryTable;
