import React, { useEffect, useState } from "react";
import Layouts from "../layouts/Layouts";
import { EmployeeWorkProgress } from "../components/customs/WorkProgress";
import instance from "../../API/apiCall";
import { APILINK } from "../Utilities/ApiLink";
import moment from "moment";
// type Props = {}

const WorkProgress = () => {
  const token = localStorage.getItem("token");
  const user_Id = localStorage.getItem("user_Id");
  const role = localStorage.getItem("role");
  const [singleData, setsingleData] = useState([]);
  const [workProgress, setWorkProgress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState(moment(new Date()).format("YYYY-MM"));
  const [count, setCount] = useState([{}]);
  const [search, setSearch] = useState([]);
  // Get all Employee datas
  // const getWorkProgress = () => {
  //   instance({
  //     url: APILINK.attendance_insert,
  //     method: "GET",
  //     params: role === "1" ? {} : { id: user_Id },
  //     headers: {
  //       token: token,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.status == 200) {
  //         if (response.data.status != 401) {
  //           const responseData = response.data.data;
  //           setWorkProgress(responseData);
  //           setsingleData([]);
  //         } else if (parseInt(response.data.status) === 401) {
  //           window.location.href = "/";
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "err");
  //     });
  // };

  // useEffect(() => {
  //   getWorkProgress();
  // }, []);

  const [dates, setDates] = useState([{}]);
  const getYear = () => {
    setLoading(true);
    const params = {
      year: moment(years).format("YYYY"),

      month: moment(years).format("MM"),
      id: role === "1" ? "" : user_Id,
    };
    instance({
      url: APILINK.attendance_insert,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.data.status == 200) {
          if (response.data.status != 401) {
            const responseData = response.data.data;
            console.log(responseData, "res");
            setWorkProgress(responseData);
            setsingleData([]);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getYear();
  }, []);
  return (
    <Layouts>
      <EmployeeWorkProgress
        workProgress={workProgress}
        singleData={singleData}
        // getWorkProgress={getWorkProgress}
        years={years}
        setYears={setYears}
        getYear={getYear}
        dates={dates}
        search={search}
      />
    </Layouts>
  );
};

export default WorkProgress;
