import React from "react";
import Layouts from "../layouts/Layouts";

// type Props = {}

const Employees = () => {
  return <Layouts>Employees</Layouts>;
};

export default Employees;
