import React, { useEffect, useState } from "react";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { BsPlusLg } from "react-icons/bs";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import { BsCaretDownFill } from "react-icons/bs";

type ExpensesProps = { setExpense: any; expensesList: any; expense: any };

const ExpensesHeaader: React.FC<ExpensesProps> = ({
  setExpense,
  expensesList,
  expense,
}) => {
  const navigate = useNavigate();
  const [purchasedBy, setPurchasedBy] = useState([
    {
      label: "Select Employee",
      value: "",
      hidden: true,
    },
  ]);

  const [dateFrom, setdateFrom] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateTo, setdateTo] = useState(Moment(new Date()).format("YYYY-MM-DD"));
  const [itemName, setItemName] = useState("");
  const [purchase, setPurchase] = useState("");

  const token = localStorage.getItem("token");

  const expenseAdd = () => {
    localStorage.setItem("expense_Id", "");
    navigate("/addexpenses");
  };

  // filter expenses api funtion call

  const filterExpense = () => {
    const params = {
      item_name: itemName,
      purchase_by: purchase,
      date_from: dateFrom,
      date_to: dateTo,
    };
    instance({
      url: APILINK.expenses,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            setExpense(response.data.data);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const convertArrayOfObjectsToCSV = (array: any) => {
    let result: any;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    if (array.length > 0) {
      const keys = Object.keys(array[1]);
      result = "";
      result += "Expenses";
      result += lineDelimiter;

      array?.forEach((item: any) => {
        let ctr = 0;
        keys?.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];

          ctr++;
        });
        result += lineDelimiter;
      });
    }

    return result;
  };

  const downloadCSV = async () => {
    const link = document.createElement("a");
    const headers = [
      [
        "Employee Name",
        "Item Name",
        "Pay Type",
        "Purchased Date",
        "Purchased From",
        "Amount",
        "Status",
      ],
    ];
    const employeeData = expense?.map((expense: any) => [
      expense.first_name,
      expense.item_name,
      expense.pay_type,
      expense.purchaseDate,
      expense.purchase_from,
      expense.amount,
      expense.status,
    ]);

    const employees: any = headers.concat(employeeData);

    let csv = await convertArrayOfObjectsToCSV(employees);

    if (csv == null) return;

    const filename = "employees.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  // Get all employees

  const getEmployee = () => {
    instance({
      url: APILINK.employee_get,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            const responseData = response.data.data;
            const oldEmployeeList: any = [];
            oldEmployeeList.push({
              label: "Select Employee",
              value: "",
              hidden: true,
            });
            responseData?.map((emp: any) => {
              const employee = {
                label: `${emp.first_name} ${emp.last_name}`,
                value: emp.id,
              };
              oldEmployeeList.push(employee);
              setPurchasedBy(oldEmployeeList);
            });
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const clearExpense = () => {
    expensesList();
    setdateFrom("");
    setdateTo("");
    setPurchase("");
    setItemName("");
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <>
      <div className="row ">
        <div className="col-sm-8 col-5">
          <h4 className="page-title">Expenses</h4>
        </div>
        <div className="col-sm-4 col-7 text-right m-b-30">
          <li
            // href="#"
            id="downloadStudents"
            className="btn btn-primary  float-right"
            title="Download Expenses"
            data-toggle="dropdown"
            onClick={() => {
              downloadCSV();
            }}
          >
            <FaDownload title="Download Expenses" />
          </li>
          <div
            onClick={expenseAdd}
            className="btn btn-primary  float-right mr-2"
          >
            <BsPlusLg title="Add Expense" />
          </div>
        </div>
      </div>
      <div className="row filter-row form-group">
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className=" ">
            {/* <label className="focus-label">Item Name</label> */}
            <input
              placeholder="Item Name"
              type="text"
              className="form-control floating"
              onChange={(e) => setItemName(e.target.value)}
              value={itemName}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 position-relative">
          <div className="  select-focus">
            {/* <label className="focus-label">Purchased By</label> */}
            <select
              className="select form-control floating w-100 icons"
              onChange={(e: any) => setPurchase(e.target.value)}
              value={purchase}
            >
              {purchasedBy?.map((items: any) => {
                return (
                  <option
                    key={items.id}
                    hidden={items.hidden}
                    value={items.value}
                  >
                    {items.label}
                  </option>
                );
              })}
            </select>
            <BsCaretDownFill className="search-iconone position-absolute" />
          </div>
        </div>
        {/*   <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="  select-focus">
            <select className="select form-control floating w-100">
              {paidBy?.map((items: any) => (
                <option key={items.id} hidden={items.hidden}>
                  {items.name}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className=" ">
            {/* <label className="focus-label">From</label> */}
            <div>
              <input
                className="form-control "
                type="date"
                onChange={(e) => setdateFrom(e.target.value)}
                value={dateFrom}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className=" ">
            {/* <label className="focus-label">To</label> */}
            <div className="">
              <input
                className="form-control "
                type="date"
                onChange={(e) => setdateTo(e.target.value)}
                value={dateTo}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <a className="btn btn-success btn-block" onClick={filterExpense}>
            Search
          </a>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <a className="btn btn-warning btn-block" onClick={clearExpense}>
            Clear
          </a>
        </div>
      </div>
    </>
  );
};

export default ExpensesHeaader;
