/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React, { useState } from "react";

import instance from "../../../API/apiCall";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Moment from "moment";
import { APILINK } from "../../Utilities/ApiLink";
import Spinner from "react-bootstrap/esm/Spinner";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const $ = require("jquery");

type CalendarProps = {
  dates: any;
  years: any;
  setYears: any;
  getYear: any;
  search: any;
};

const Calendar: React.FC<CalendarProps> = ({ years }) => {
  const [customButtons, setcustomButtons] = useState({
    left: "title",
    right: "prev,next",
  });
  const [search, setSearch] = useState([]);
  const [intime, setIntime] = useState();
  const [outtime, setOuttime] = useState();
  const [loadingBtn, setloadingBtn] = useState(false);
  const [dates, setDates] = useState([{}]);
  const token = localStorage.getItem("token");
  const employeeId: any = localStorage.getItem("user_Id");

  const getYear = (years: any) => {
    setloadingBtn(true);
    const params = {
      year: Moment(years).format("YYYY"),

      month: Moment(years).format("MM"),
      emp_id: employeeId,
    };
    instance({
      url: APILINK.attendance_list,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          setloadingBtn(false);
          const dataa = response.data.data;
          setIntime(dataa[0]?.in_time);
          setOuttime(dataa[0]?.out_time);
          setSearch(response.data.data);
          setDates(Object.keys(response.data.data[0]));
        } else if (response.data.status == 401) {
          window.location.href = "/";
        } else {
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  let data: any = [{}];
  let events: any = [];
  {
    search?.map((item: any) => {
      const dates = Object.keys(item);
      data = dates?.map((data) => {
        return (events = {
          start: data,
          allDay: true,
          display: "background",
          backgroundColor:
            item[data] === "P"
              ? "#90EE90"
              : item[data] === "A"
              ? " #ffc0cb"
              : item[data] === "L"
              ? "#EEE8AA"
              : "",

          title:
            item[data] === "P"
              ? "Present"
              : item[data] === "A"
              ? "Absent"
              : "Holiday",
          textColor:
            item[data] === "P"
              ? "#051d05"
              : item[data] === "A"
              ? " #ff1493"
              : item[data] === "L"
              ? "#FFA500"
              : "",
        });
      });
    });
  }

  const renderEventContent = (event: any) => {
    const status = event?.event.title;

    return (
      <p
        className={
          status == "Present"
            ? "mb-1 border-class  present-text "
            : status == "Absent"
            ? "mb-1 border-class absent-text"
            : "mb-1 border-class leave-text"
        }
      >
        {status == "Present" ? (
          <>
            <label className="mb-0 ml-3  ">{event.event.title}</label>
            {/*  <label className="present-text  mt-1 ">{intime}</label>
            <label className="present-text   ">{outtime}</label> */}
          </>
        ) : (
          <label className="mb-0  ml-3">{event.event.title}</label>
        )}
      </p>
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  $(".fc-prev-button").click(function () {
    var elements = $(".fc-toolbar-title");
    const titleOfMonth = elements[0]?.innerHTML;
    getYear(titleOfMonth);
  });
  $(".fc-next-button").click(function () {
    var elements = $(".fc-toolbar-title");
    const titleOfMonth = elements[0]?.innerHTML;
    getYear(titleOfMonth);
  });

  return (
    <>
      {loadingBtn && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <FullCalendar
        headerToolbar={customButtons}
        plugins={[dayGridPlugin, interactionPlugin]}
        showNonCurrentDates={false}
        initialView="dayGridMonth"
        weekends
        eventContent={renderEventContent}
        fixedWeekCount={false}
        events={data}
        editable={false}
        dayMaxEvents
      />
    </>
  );
};

export default Calendar;
