/** @format */

import axios from "axios";
import { SERVERURL } from "../app/Utilities/Constants";

const instance = axios.create({
  baseURL: SERVERURL,
  headers: {
    "Content-Type": "application/json",
  },
  // .. other options
});

export default instance;
