import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Moment from "moment";
import HolidaysTable from "./HolidaysTable";
import "jspdf-autotable";
import jspdf from "jspdf";
import { GrDocumentPdf } from "react-icons/gr";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaDownload } from "react-icons/fa";
import { BsPlusLg } from "react-icons/bs";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";

// type Props = {}

const Holiday = () => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [holidays, setHolidays] = useState([]);

  const getHolidays = async () => {
    //Get API call for listing holidays
    await instance({
      // url of the api endpoint (can be changed)
      url: APILINK.holidays,
      method: "GET",
      // params: param,
      headers: {
        token: token,
      },
    }).then(async (response) => {
      const holiday = response.data.data.map((item: any) => {
        return {
          ...item,
          day: Moment(item.date).format("dddd"),
          date: Moment(item.date).format("DD/MM/YYYY"),
        };
      });
      setHolidays(holiday);
    });
  };
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;

    const doc: any = new jspdf(orientation, unit, size);
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.setFontSize(14);

    const title = "Holidays ";
    const headers = [["Title", "Holiday Date", "Day"]];

    const holidayData = holidays?.map((holiday: any) => [
      holiday.holiday,
      holiday.date,
      holiday.day,
    ]);

    const content = {
      startY: 50,
      head: headers,
      body: holidayData,
    };

    doc.text(title, pageWidth / 2, 40);
    doc.autoTable(content);
    doc.save("holidays.pdf");
  };

  const addHoliday = () => {
    localStorage.setItem("holiday_id", "");
    navigate("/addHolidays");
  };
  useEffect(() => {
    getHolidays();
  }, []);
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-sm-5 col-5">
            <h4 className="page-title">Holidays </h4>
          </div>
          {role == "1" && (
            <div className="col-sm-7 col-7 text-right m-b-30">
              <div className="dropdown">
                <li
                  // href="#"
                  id="downloadStudents"
                  className="btn btn-primary  float-right"
                  title="Download employee"
                  data-toggle="dropdown"
                  onClick={() => {
                    exportPDF();
                  }}
                >
                  <FaDownload title="Download Holiday" />
                </li>
              </div>
              <div onClick={addHoliday} className="btn btn-primary mr-3">
                <BsPlusLg title="Add Holiday" />
              </div>
            </div>
          )}
        </div>
        <HolidaysTable />
      </div>
    </div>
  );
};

export default Holiday;
