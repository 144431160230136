import React, { useEffect, useState } from "react";
import Layouts from "../layouts/Layouts";
import Projects from "../components/customs/Projectpage/Projects";
import { FaEllipsisH, FaPencilRuler, FaTrash } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router";

import Addproject from "../components/customs/Projectpage/addproject";
import { APILINK } from "../Utilities/ApiLink";
import instance from "../../API/apiCall";
const Pojectmap = () => {
  const navigate = useNavigate();

  const AddProject = () => {
    navigate("/addmaping");
  };

  const [taskname, setTaskname] = useState("");
  const [category, setcategory] = useState("");
  const [statuses, setstatuses] = useState("");
  const [arrayVal, setArrayVal] = useState([]);
  const [activity, setActivity] = useState([]);
  const [users, setusers] = useState("");
  const [employeeDetailsOne, setEmployeeDetailsOne] = useState([]);

  const [startdste, setstartdate] = useState("");
  const [comments, setcomments] = useState("");

  const [showButtons, setShowButtons] = useState(false);

  function handleClick() {
    setShowButtons(!showButtons);
  }
  const getactivity = async () => {
    await instance({
      // url of the api endpoint (can be changed)
      url: APILINK.activity,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          setArrayVal(response.data.data);
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // local storage value
  const navi = useNavigate();

  // add leave function
  const addmaping = (id: any) => {
    localStorage.setItem("projectId", id);

    navi("/addmaping");
  };
  const [projectId, setProjectId] = useState(localStorage.getItem("projectId"));
  const [id, setId] = useState();

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_Id");
  const Role = localStorage.getItem("role");

  //edit

  const editLeave = (id: any) => {
    localStorage.setItem("leave_Id", id);
    navigate("/addmaping");
  };

  //  delete the Card

  // const deleteLeave = () => {};
  const projectGet = () => {
    const params = {};

    instance({
      url: APILINK.activity,
      method: "GET",
      params: {},
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            setActivity(response.data.data);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const onDelete = (id: any) => {
    const params = {
      id: id,
    };
    instance({
      url: `${APILINK.activity}/${id}`,
      method: "DELETE",
      data: params,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          // window.location.href = "/";
          getactivity();
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        } else {
          // window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const employeeDetails = async () => {
    await instance({
      url: APILINK.employee_get,
      method: "GET",
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        setEmployeeDetailsOne(response.data.data);
      } else if (parseInt(response.data.status) === 401) {
        window.location.href = "/";
      } else {
        // window.location.href = "/";
      }
    });
  };
  useEffect(() => {
    getactivity();
    projectGet();
    employeeDetails();
  }, []);
  return (
    <Layouts>
      <div className="page-wrapper">
        <div className="content">
          <div>
            <div className="row">
              <div className="col-sm-8 col-6">
                <h4 className="page-title">Project Page</h4>
              </div>

              <div className="col-sm-4 mt-5 col-6 text-right m-b-30 ">
                <div
                  onClick={() => addmaping(projectId)}
                  className="btn mt-5 mr-5 btn-primary btn-rounded float-right"
                >
                  Add project
                </div>
              </div>
            </div>

            <table className="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th>empl no</th>
                  <th>project_title</th>
                  <th>Users</th>
                  <th>Description</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {activity.map((item: any) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.project_id}</td>
                      <td>{item.project_title}</td>
                      <td>{}</td>
                      <td>{item.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default Pojectmap;
