import moment from "moment";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import { useNavigate } from "react-router-dom";
// type Props = {}

const AddHoliday = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const holidayId = localStorage.getItem("holiday_id");
  const [holidays, setholidays] = useState({
    holiday: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
  });

  const holidaySchema = Yup.object().shape({
    holiday: Yup.string().required("Holiday Name is required"),
    date: Yup.string().required("Holiday Date is required"),
  });
  const onSubmit = (values: any) => {
    instance({
      url: holidayId ? `${APILINK.holidays}/id=${holidayId}` : APILINK.holidays,
      method: holidayId ? "PUT" : "POST",
      data: holidayId ? { ...values, id: holidayId } : values,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          navigate("/holidays");
          localStorage.setItem("holiday_id", "");
        } else if (response.status == 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getSingleHoliday = () => {
    const params = {
      id: holidayId,
    };
    instance({
      url: APILINK.holidays,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data[0];
          if (response.data.data.length > 0) {
            setholidays({
              holiday: responseData.holiday,
              date: moment(responseData.date).format("YYYY-MM-DD"),
            });
          }
        } else if (response.status == 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  useEffect(() => {
    holidayId && getSingleHoliday();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h4 className="page-title">Add Holiday</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <Formik
              initialValues={holidays}
              onSubmit={(values: any) => onSubmit(values)}
              validationSchema={holidaySchema}
              enableReinitialize={true}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <>
                  <div className="card-box">
                    <div className="form-group">
                      <label>
                        Holiday Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Holiday Name"
                        name="holiday"
                        value={values.holiday}
                        onChange={handleChange("holiday")}
                        onBlur={handleBlur("holiday")}
                      />
                      {errors.holiday && touched.holiday && (
                        <p className="text-danger">{errors.holiday}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Holiday Date
                        <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        <div className="react-datepicker-wrapper">
                          <div className="react-datepicker__input-container">
                            <input
                              type="date"
                              name="date"
                              className="form-control datetimepicker"
                              value={values.date}
                              onChange={handleChange("date")}
                              onBlur={handleBlur("date")}
                            />
                            {errors.date && touched.date && (
                              <p className="text-danger">{errors.date}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-t-20 text-center">
                      <input
                        onClick={() => handleSubmit()}
                        type="button"
                        className="btn btn-primary submit-btn"
                        value={holidayId ? "Update Holiday" : "Create Holiday"}
                      />
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHoliday;
