import React, { useEffect, useState } from "react";
import Moment from "moment";
import Layouts from "../layouts/Layouts";
import { EmployeeList, EmployeeTable } from "../components/customs/Employee";
import instance from "../../API/apiCall";
import { APILINK } from "../Utilities/ApiLink";

// type Props = {}

const EmployeesList = () => {
  const token = localStorage.getItem("token");

  const [role, setRole] = useState([
    {
      label: "Select Role",
      value: "",
      hidden: true,
    },
  ]);
  const [employeListDropDown, setEmployeListDropDown] = useState([
    {
      label: "Select Employee",
      value: "",
      hidden: true,
    },
  ]);
  const [employeList, setEmployeList] = useState([]);
  const [filterEmployeList, setFilterEmployeList] = useState([]);
  const [id, setId] = useState();

  const deleteEmployee = () => {
    const params = {
      id: id,
    };
    instance({
      url: `${APILINK.employee_get}/id=${id}`,
      method: "DELETE",
      data: params,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          //  navigate("/employeeslist");

          getEmployee();
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // get Role
  const getRole = () => {
    instance({
      url: APILINK.role,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;
          const oldRole: any = [];
          oldRole.push({
            label: "Select Role",
            value: "",
            hidden: true,
          });
          responseData.map((rol: any) => {
            const role = {
              label: rol.role,
              value: rol.id,
            };
            oldRole.push(role);
            setRole(oldRole);
          });
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // Get all Employee datas
  const getEmployee = () => {
    instance({
      url: APILINK.employee_get,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status != 401) {
            const responseData = response.data.data;
            const result = responseData.map((item: any) => {
              return { ...item, Dob: Moment(item.dob).format("DD/MM/YYYY") };
            });
            setEmployeList(result);
            setFilterEmployeList(responseData);
            const oldEmployee: any = [];
            oldEmployee.push({
              label: "Select Employee",
              value: "",
              hidden: true,
            });
            responseData?.map((emp: any) => {
              const employee = {
                label: `${emp.first_name} ${emp.last_name}`,
                value: emp.id,
              };
              oldEmployee.push(employee);
              setEmployeListDropDown(oldEmployee);
            });
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // filter employe
  const employeFilter = (values: any) => {
    const filterList = filterEmployeList?.filter((emp: any) => {
      if (values.employee && values.role) {
        if (
          `${emp.first_name} ${emp.last_name}` == values.employee &&
          emp.role_id == values.role
        ) {
          return emp;
        }
      } else if (values.employee && !values.role) {
        if (`${emp.first_name} ${emp.last_name}` == values.employee) {
          return emp;
        }
      } else if (!values.employee && values.role) {
        if (emp.role_id == values.role) {
          return emp;
        }
      } else {
        return emp;
      }
    });
    setEmployeList(filterList);
  };
  useEffect(() => {
    getEmployee();
    getRole();
  }, []);
  return (
    <Layouts>
      <div className="page-wrapper">
        <div className="content">
          <EmployeeList
            employeList={employeListDropDown}
            employee={employeList}
            role={role}
            employeFilter={employeFilter}
            getEmployee={getEmployee}
          />
          {employeList.length > 0 ? (
            <EmployeeTable
              employeList={employeList}
              setId={setId}
              deleteEmployee={deleteEmployee}
              getEmployee={getEmployee}
            />
          ) : (
            <h3 className="d-flex justify-content-center">No data found!</h3>
          )}
        </div>
      </div>
    </Layouts>
  );
};

export default EmployeesList;
