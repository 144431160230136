/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { BsPlusLg } from "react-icons/bs";
import "jspdf-autotable";
import jspdf from "jspdf";
import { BsCaretDownFill } from "react-icons/bs";

import { GrDocumentPdf } from "react-icons/gr";
import { SiMicrosoftexcel } from "react-icons/si";

type EmployeeListProps = {
  employeList: any;
  role: any;
  employeFilter: any;
  getEmployee: any;
  employee: any;
};

const EmployeeList: React.FC<EmployeeListProps> = ({
  employeList,
  role,
  employeFilter,
  getEmployee,
  employee,
}) => {
  const [search, setSearch] = useState({
    employee: "",
    role: "",
  });
  const roles = localStorage.getItem("role");

  const navigate = useNavigate();
  // add employee function
  const addEmploye = () => {
    localStorage.setItem("employee_id", "");
    navigate("/addemployee");
  };

  const convertArrayOfObjectsToCSV = (array: any) => {
    let result: any;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    if (array.length > 0) {
      const keys = Object.keys(array[1]);
      result = "";
      result += "Employee Information";
      result += lineDelimiter;

      array?.forEach((item: any) => {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];

          ctr++;
        });
        result += lineDelimiter;
      });
    }

    return result;
  };
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;

    const doc: any = new jspdf(orientation, unit, size);
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.setFontSize(14);

    const title = "Employee Informations ";
    const headers = [
      ["Employee ID", "First Name", "Last Name", "Email", "Role", "Phone"],
    ];

    const employeeData = employee?.map((employee: any) => [
      employee.emp_id,
      employee.first_name,
      employee.last_name,
      employee.email,
      employee.role,
      employee.mobile,
    ]);

    const content = {
      startY: 50,
      head: headers,
      body: employeeData,
    };

    doc.text(title, pageWidth / 2, 40);
    doc.autoTable(content);
    doc.save("employees.pdf");
  };

  const downloadCSV = async () => {
    const link = document.createElement("a");
    const headers = [
      [
        "First Name",
        "Last Name",
        "Role",
        "Email",
        "Date of Birth",
        "Mobile Number",
      ],
    ];
    const employeeData = employee?.map((employee: any) => [
      employee.first_name ? employee.first_name : "",
      employee.last_name ? employee.last_name : "",
      employee.role ? employee.role : "",
      employee.email ? employee.email : "",
      employee.Dob ? employee.Dob : "",
      employee.mobile ? employee.mobile : "",
    ]);

    const employees: any = headers.concat(employeeData);

    let csv = await convertArrayOfObjectsToCSV(employees);

    if (csv == null) return;

    const filename = "employees.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  const setSearchValues = (value: string, tag: string) => {
    if (tag == "role") {
      setSearch({
        ...search,
        role: value,
      });
    } else {
      setSearch({
        ...search,
        employee: value,
      });
    }
  };

  const clearEmployeeList = () => {
    getEmployee();
    setSearch({
      employee: "",
      role: "",
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-4 col-3">
          <h4 className="page-title">Employees</h4>
        </div>
        <div className="col-sm-8 col-9 text-right ">
          <div className="dropdown">
            <li
              id="downloadStudents"
              className="btn btn-primary  float-right"
              title="Download employee"
              data-toggle="dropdown"
            >
              <FaDownload />
            </li>
            <div className="dropdown-menu ">
              <ul className="list-unstyled user-profile-nav mb-0 dropdown-item">
                <li
                  className=" pointer "
                  onClick={() => {
                    exportPDF();
                  }}
                >
                  <GrDocumentPdf />
                  <label className="p-2 mb-0">Download as pdf</label>
                </li>
                <li
                  className=" pointer"
                  onClick={() => {
                    downloadCSV();
                  }}
                >
                  <SiMicrosoftexcel />
                  <label className="p-2 mb-0">Download as Excel</label>
                </li>
              </ul>
            </div>
          </div>
          {roles == "1" && (
            <div
              onClick={addEmploye}
              className="btn btn-primary  float-right mr-3"
            >
              <BsPlusLg title="Add Employee" />
            </div>
          )}
        </div>
      </div>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3 position-relative select floating">
          <div className="form-group ">
            <select
              className="select floating icons w-100 form-control"
              value={search.employee}
              onChange={(e) => setSearchValues(e.target.value, "employee")}
            >
              {employeList?.map((item: any) => {
                return (
                  <option key={item.id} hidden={item.hidden} value={item.vlaue}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            <BsCaretDownFill className="search-iconone position-absolute" />
          </div>
        </div>
        <div className="col-sm-6 col-md-3 position-relative">
          <div className="form-group  select-focus">
            <select
              className="select floating icons w-100 form-control"
              value={search.role}
              onChange={(e) => setSearchValues(e.target.value, "role")}
            >
              {role?.map((item: any) => {
                return (
                  <option key={item.id} hidden={item.hidden} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            <BsCaretDownFill className="search-iconone position-absolute" />
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <input
            type="button"
            className="btn btn-success btn-block"
            value={"Search"}
            onClick={() => employeFilter(search)}
          />
        </div>
        <div className="col-sm-6 col-md-3 ">
          <a className="btn btn-warning btn-block" onClick={clearEmployeeList}>
            Clear
          </a>
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;
