import React, { useEffect, useState } from "react";
import Moment from "moment";

import { FaEllipsisV, FaPencilRuler, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";

// type Props = {}

const HolidaysTable = () => {
  const date = new Date();
  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  const [holidays, setHolidays] = useState([]);
  // const token = localStorage.getItem("token");
  const token = localStorage.getItem("token");
  //get api function
  const getHolidays = async () => {
    //Get API call for listing holidays
    await instance({
      // url of the api endpoint (can be changed)
      url: APILINK.holidays,
      method: "GET",
      // params: param,
      headers: {
        token: token,
      },
    }).then(async (response) => {
      setHolidays(response.data.data);
    });
  };

  const editeHoliday = (id: any) => {
    localStorage.setItem("holiday_id", id);
    navigate("/addHolidays");
  };

  const deleteHoliday = (id: any) => {
    const params = {
      id: id,
    };
    instance({
      url: `${APILINK.holidays}/id=${id}`,
      method: "DELETE",
      data: params,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            getHolidays();
          }
        } /* else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        } */
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getHolidays();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-striped custom-table mb-0">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Holiday Date</th>
                <th>Day</th>
                {role == "1" && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {holidays?.map((item: any) => {
                return (
                  <tr
                    key={item.id}
                    className={
                      new Date(item.date).getTime() <= date.getTime()
                        ? "holiday-completed"
                        : "holiday-upcoming"
                    }
                  >
                    <td>{item.id}</td>
                    <td>{item.holiday}</td>
                    <td>{Moment(item.date).format("DD/MM/YYYY")}</td>
                    <td>{Moment(item.date).format("dddd")}</td>
                    {role == "1" && (
                      <td>
                        {new Date(item.date).getTime() <= date.getTime() ? (
                          ""
                        ) : (
                          <div className="dropdown dropdown-action">
                            <a
                              href="#"
                              className="action-icon dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <FaEllipsisV />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a
                                className="dropdown-item"
                                onClick={() => editeHoliday(item.id)}
                              >
                                <FaPencilRuler /> Edit
                              </a>

                              <a
                                onClick={() => deleteHoliday(item.id)}
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="#delete_holiday"
                              >
                                <FaTrash className="m-r-10" />
                                Delete
                              </a>
                              {/* </a> */}
                            </div>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HolidaysTable;
