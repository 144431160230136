import React, { useEffect, useState } from "react";
import { FcLeave } from "react-icons/fc";
import {
  BsFillCalendar2CheckFill,
  BsCalendar2XFill,
  BsFillCalendar2PlusFill,
} from "react-icons/bs";
import instance from "../../../API/apiCall";
import { APILINK } from "../../Utilities/ApiLink";

// type Props = {};

const EmployeeLeaves = () => {
  const [leaves, setLeaves] = useState([
    {
      id: 1,
      leaves: "Number of leaves",
      icon: <BsFillCalendar2CheckFill className="leave-icon text-success" />,
      no_of_leaves: 0,
    },
    {
      id: 2,
      leaves: "Number of leaves taken",
      icon: <BsCalendar2XFill className="leave-icon text-danger" />,
      no_of_leaves: 0,
    },
    {
      id: 3,
      leaves: "Number of leaves remaining",
      icon: <BsFillCalendar2PlusFill className="leave-icon text-warning" />,
      no_of_leaves: 0,
    },
  ]);

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("user_Id");

  const [leaveCount, setLeaveCount] = useState([]);

  const leaveList = async () => {
    const param = {
      role: "admin",
    };
    const param1 = {
      emp_id: userId,
    };

    await instance({
      // url of the api endpoint (can be changed)
      url: APILINK.leave_type,
      method: "GET",
      // params: role === "1" ? param : param1,
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        if (response.data.status !== 401) {
          const responseData = response.data.data;
          // setLeaveCount(response.data.leave_count);
          let newTotal = {
            total: 0,
            taken: 0,
            remaining: 0,
          };
          responseData.map((item: any) => {
            newTotal = {
              total:
                item.total_days == null
                  ? newTotal.total + 0
                  : newTotal.total + item.total_days,
              taken: item.emp_taken_leave + newTotal.taken,
              remaining: newTotal.total - newTotal.taken,
            };
            setLeaves([
              {
                id: 1,
                leaves: "Number of leaves",
                icon: (
                  <BsFillCalendar2CheckFill className="leave-icon text-success" />
                ),
                no_of_leaves: newTotal.total,
              },
              {
                id: 2,
                leaves: "Number of leaves taken",
                icon: <BsCalendar2XFill className="leave-icon text-danger" />,
                no_of_leaves: newTotal.taken,
              },
              {
                id: 3,
                leaves: "Number of leaves remaining",
                icon: (
                  <BsFillCalendar2PlusFill className="leave-icon text-warning" />
                ),
                no_of_leaves: newTotal.remaining,
              },
            ]);
          });
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      }
    });
  };

  useEffect(() => {
    leaveList();
  }, []);

  return (
    <div className="row">
      {leaves?.map((item) => {
        return (
          <div className="col-md-4" key={item.id}>
            <div className="card ">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="pr-3">{item.icon}</div>
                  <div className="">
                    <h5 className="card-title">{item.leaves}</h5>
                    <h5 className="card-subtitle text-muted">
                      {item.no_of_leaves}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmployeeLeaves;
