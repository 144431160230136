/* eslint-disable @typescript-eslint/no-explicit-any */
// import React, { useEffect, useState } from "react";
import instance from "../../../../API/apiCall";
import Layouts from "../../../layouts/Layouts";
import { APILINK } from "../../../Utilities/ApiLink";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik, validateYupSchema } from "formik";
import Moment from "moment";
import { BsCaretDownFill } from "react-icons/bs";
import Button from "../../basics/button/Button";
import Select from "react-select";
////new code

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

// type Props = {};

const Addproject = () => {
  const navigate = useNavigate();
  // const leave = [
  //   {
  //     id: 1,
  //     projectMember: "Leave Type",
  //     hidden: true,
  //     status: "Leave Status",
  //     projectName: "Attendance",
  //   },
  //   {
  //     id: 2,
  //     projectMember: "Casual leave",
  //     hidden: false,
  //     status: "Pending",
  //     projectName: "Restarunt EPOS",
  //   },
  //   {
  //     id: 3,
  //     projectMember: "Medical Leave",
  //     hidden: false,
  //     status: "Approved",
  //     projectName: "Attendance",
  //   },
  //   {
  //     id: 4,
  //     projectMember: "Loss of pay",
  //     hidden: false,
  //     status: "Declined",
  //     projectName: "Attendance",
  //   },
  // ];
  const [numOfDays, setNumOfDays] = useState(0);
  const [employeeDetailsOne, setEmployeeDetailsOne] = useState([]);
  const [fromDate, setFromDate] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(Moment(new Date()).format("YYYY-MM-DD"));

  const [empLeaveType, setEmpLeaveType] = useState([]);

  const [addproject, setaddproject] = useState({
    projectMember: "",
    number_of_days: "",
    /* remaining_leaves: "", */
    description: "",
    project_title: "",
  });

  const addprojectForm = Yup.object().shape({
    // projectMember: Yup.string().required("required"),
    description: Yup.string().required("comments  required"),
    project_title: Yup.string().required("project_title is required"),
  });

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_Id");
  const leave_Id = localStorage.getItem("leave_Id");

  // get single leave
  const getSingleLeave = () => {
    const params = {};

    instance({
      url: `${APILINK.project}/${leave_Id}`,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data[0];
          if (response.data.data.length > 0) {
            setaddproject({
              ...responseData,
            });
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // add and update api call

  const newProject = async (values: any) => {
    const param = {
      project_title: values.project_title,
      description: values.description,
    };

    await instance({
      // url of the api endpoint (can be changed)
      url: leave_Id ? `${APILINK.project}/${leave_Id}` : APILINK.project,
      // url: `${APILINK.project}/${leave_Id}`,
      // method: "PUT",
      method: leave_Id ? "PUT" : "POST",
      // data: param,
      data: leave_Id ? { ...param, id: leave_Id } : param,

      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          navigate("/project");
          localStorage.setItem("leave_Id", "");
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  //leave type api
  const employeeDetails = async () => {
    await instance({
      url: APILINK.employee_get,
      method: "GET",
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        setEmployeeDetailsOne(response.data.data);
      } else if (parseInt(response.data.status) === 401) {
        window.location.href = "/";
      } else {
        // window.location.href = "/";
      }
    });
  };

  const employeeLeaveType = async () => {
    await instance({
      url: APILINK.project,
      method: "GET",
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        if (response.data.status !== 400) {
          const empArray: any = [];
          empArray.push({
            label: "Leave Type",
            value: "",

            hidden: true,
          });
          response.data.data.map((item: any) => {
            (item.emp_taken_leave === null ? 0 : item.emp_taken_leave) <=
              item.total_days &&
              empArray.push({
                label: item.projectMember,
                value: item.id,
                describe,
              });
            setEmpLeaveType(empArray);
          });
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      }
    });
  };

  useEffect(() => {
    leave_Id && getSingleLeave();
    employeeDetails();
    employeeLeaveType();
  }, []);

  useEffect(() => {
    leave_Id && getSingleLeave();
    employeeLeaveType();
  }, []);
  return (
    <Layouts>
      <div className="page-wrapper ">
        <div className="content">
          <Formik
            initialValues={addproject}
            onSubmit={(values) => newProject(values)}
            validationSchema={addprojectForm}
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <div className="row">
                  <div className="col-sm-6">
                    <h4 className="page-title">
                      {leave_Id ? "Edit Project" : "Add project"}
                    </h4>
                  </div>
                </div>
                <form>
                  <div className="card-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group width100">
                                <label>
                                  Project Title
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="textarea"
                                  className="form-control"
                                  onChange={handleChange("project_title")}
                                  onBlur={handleBlur("project_title")}
                                  value={values.project_title}
                                />

                                {errors.project_title &&
                                  touched.project_title && (
                                    <p className="text-danger">
                                      {errors.project_title}
                                    </p>
                                  )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <label>
                                Project Members
                                <span className="text-danger">*</span>
                              </label>
                              <div className="select floating css-2b097c-container">
                                <div className=" css-yk16xz-control position-relative">
                                  <div className=" css-1hwfws3">
                                    <div className="form-group  select-focus">
                                      {/* <Select
                                        isMulti={true}
                                        options={employeeDetailsOne?.map(
                                          (item: any) => ({
                                            value: item.value,
                                            label: `${item.first_name} ${item.last_name}`,
                                          })
                                        )}
                                        onChange={(employeeDetailsOne) =>
                                          handleChange({
                                            target: {
                                              name: "projectMember",
                                              value: employeeDetailsOne.map(
                                                (option) => option.value
                                              ),
                                            },
                                          })
                                        }
                                        onBlur={handleBlur("projectMember")}
                                        value={employeeDetailsOne
                                          ?.map((item: any) => ({
                                            value: item.value,
                                            label: `${item.first_name} ${item.last_name}`,
                                          }))
                                          .filter((option) =>
                                            values.projectMember.includes(
                                              option.value
                                            )
                                          )}
                                      /> */}

                                      <select
                                        className="select floating w-100 form-control icons"
                                        onChange={handleChange("projectMember")}
                                        onBlur={handleBlur("projectMember")}
                                        value={values.projectMember}
                                      >
                                        {employeeDetailsOne?.map(
                                          (item: any) => {
                                            return (
                                              <option
                                                key={item.id}
                                                value={item.value}
                                              >
                                                {`${item.first_name} ${item.last_name}`}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>

                                      {errors.projectMember &&
                                        touched.projectMember && (
                                          <p className="text-danger">
                                            {errors.projectMember}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group width100">
                                <label>
                                  Description
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  onChange={handleChange("description")}
                                  onBlur={handleBlur("description")}
                                  value={values.description}
                                />

                                {errors.description && touched.description && (
                                  <p className="text-danger">
                                    {errors.description}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center m-t-20">
                    <Button
                      type="submit"
                      className="btn btn-primary submit-btn"
                      onClick={() => handleSubmit()}
                      text="Save"
                    />
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </Layouts>
  );
};

export default Addproject;
