/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
import React, { useState, useEffect } from "react";
import { FaDownload } from "react-icons/fa";

type Props = {
  years: any;
  setYears: any;
  getYear: any;
  dates: any;
  search: any;
};

const Attendances: React.FC<Props> = ({
  years,
  setYears,
  getYear,
  dates,
  search,
}) => {
  const [head, setHead] = useState([]);

  const convertArrayOfObjectsToCSV = (array: any) => {
    let result: any;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    if (array.length > 0) {
      const keys = Object.keys(array[1]);
      result = "";
      result += "Attendance Information";
      result += lineDelimiter;

      array?.forEach((item: any) => {
        let ctr = 0;
        keys?.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;

          result += item[key];

          ctr++;
        });
        result += lineDelimiter;
      });
    }

    return result;
  };
  const downloadCSV = async () => {
    const headOne = dates?.map((date: any, index: any) => {
      if (
        date !== "employee_name" &&
        date !== "id" &&
        date !== "present" &&
        date != "absent" &&
        date !== "in_mode" &&
        date !== "out_mode" &&
        date !== "in_time" &&
        date !== "out_time"
      ) {
        return index + 1;
      }
    });
    const link = document.createElement("a");
    const headers = [["Employee Name", headOne]];
    const renderr = search?.map((item: any) => {
      const dates = Object.keys(item);
      const dummy: any = dates?.map((date) => {
        if (
          date !== "employee_name" &&
          date !== "id" &&
          date !== "present" &&
          date != "absent" &&
          date !== "in_mode" &&
          date !== "out_mode" &&
          date !== "in_time" &&
          date !== "out_time"
        ) {
          return item[date];
        }
      });
      setHead(dummy);
    });

    const employeeData: any = search?.map((employee: any) => [
      employee.employee_name ? employee.employee_name : "",
      head,
    ]);

    const employees: any = headers?.concat(employeeData);

    let csv = await convertArrayOfObjectsToCSV(employees);

    if (csv == null) return;

    const filename = "attendance.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };
  useEffect(() => {}, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 d-flex justify-content-between">
          <h4 className="page-title col-sm-10">Attendance Sheet</h4>
          <li
            // href="#"
            id="downloadStudents"
            className="btn btn-primary width-set  float-right  mb-2"
            title="Download attendance"
            onClick={() => {
              downloadCSV();
            }}
          >
            <FaDownload />
          </li>
        </div>
      </div>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-4">
          <div className="form-group "></div>
        </div>

        <div className="col-sm-6 col-md-4">
          <div className="form-group  ">
            <input
              type="month"
              className="select floating w-100 form-control"
              value={years}
              onChange={(e) => setYears(e.target.value)}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-4">
          <a className="btn btn-success btn-block" onClick={getYear}>
            Search
          </a>
        </div>
      </div>
    </>
  );
};

export default Attendances;
