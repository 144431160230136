import React, { useEffect, useState } from "react";
import Moment from "moment";
import instance from "../../../../API/apiCall";
import { APILINK } from "../../../Utilities/ApiLink";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { imageHandler } from "../../../Utilities/Helper";
import { SERVERURL } from "../../../Utilities/Constants";
import { BsCaretDownFill } from "react-icons/bs";

// type Props = {}

const AddExpenses = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line react/jsx-no-duplicate-props, react/no-unknown-property
  const [purchasedBy, setPurchasedBy] = useState([]);
  const [categoryImgErr, setCategoryImgErr] = useState(false);
  const [addExpense, setAddExpense] = useState({
    item_name: "",
    purchase_from: "",
    purchase_by: "",
    amount: "",
    paid_by: "",
    attachments: "",
  });
  const [uploadImg, setUploadImg] = useState(
    addExpense.attachments ? `${SERVERURL}/${addExpense.attachments}` : ""
  );
  const [paidBy, setPaidBy] = useState([
    {
      id: 1,
      name: "Select Paid By",
      hidden: true,
    },
    {
      id: 2,
      name: "Cash",
      hidden: false,
    },
    {
      id: 3,
      name: "Cheque",
      hidden: false,
    },
  ]);

  const [purchaseDate, setPurchaseDate] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );

  // Initial values

  // validation

  const addExpenseForm = Yup.object().shape({
    item_name: Yup.string().required("Item name is required"),
    purchase_from: Yup.string().required("Purchase from is required"),
    purchase_by: Yup.string().required("Purchase by is required"),
    amount: Yup.number().required("Amount is required"),
    paid_by: Yup.string().required("Paid by is required"),
  });

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_Id");
  const expenseId = localStorage.getItem("expense_Id");

  // Get all employees

  const getEmployee = () => {
    instance({
      url: APILINK.employee_get,
      method: "GET",
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status !== 401) {
            const responseData = response.data.data;
            const oldEmployeeList: any = [];
            oldEmployeeList.push({
              label: "Select Employee",
              value: "",
              hidden: true,
            });
            responseData.map((emp: any) => {
              const employee = {
                label: `${emp.first_name} ${emp.last_name}`,
                value: emp.id,
              };
              oldEmployeeList.push(employee);
              setPurchasedBy(oldEmployeeList);
            });
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // Get single expenses

  const getSingleExpenses = () => {
    const params = {
      id: expenseId,
    };
    instance({
      url: APILINK.expenses,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data[0];
          if (response.data.data.length > 0) {
            setAddExpense({
              ...responseData,
              purchase_by: responseData.emp_id,
              paid_by: responseData.pay_type,
            });
            setPurchaseDate(
              Moment(responseData.purchase_date).format("YYYY-MM-DD")
            );
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  // Add Expenses api funtion call

  const addExpenses = async (values: any) => {
    const payee: any = parseInt(values.purchase_by);
    const id: any = expenseId;
    const formData = new FormData();
    expenseId && formData.append("id", id);

    formData.append("item_name", values.item_name);
    formData.append("purchase_date", purchaseDate);
    formData.append("purchase_from", values.purchase_from);
    formData.append("payee", payee);
    formData.append("pay_type", values.paid_by);
    formData.append("attachment", uploadImg);
    formData.append("file", uploadImg);
    formData.append("amount", values.amount);

    await instance({
      url: expenseId ? `${APILINK.expenses}/id=${expenseId}` : APILINK.expenses,
      method: expenseId ? "PUT" : "POST",
      data: formData,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          navigate("/expenses");
          localStorage.setItem("expense_Id", "");
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    expenseId && getSingleExpenses();
    getEmployee();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <Formik
          initialValues={addExpense}
          onSubmit={(values: any) => addExpenses(values)}
          validationSchema={addExpenseForm}
          enableReinitialize={true}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
          }) => (
            <>
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <h4 className="page-title">
                    {expenseId ? "Edit Expense" : "New Expense"}
                  </h4>
                </div>
              </div>
              <div className="row card-box">
                <div className="col-lg-8 offset-lg-2">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Item Name</label>
                        <input
                          className="form-control"
                          type="text"
                          onChange={handleChange("item_name")}
                          onBlur={handleBlur("item_name")}
                          value={values.item_name}
                        />
                        {errors.item_name && touched.item_name && (
                          <p className="text-danger">{errors.item_name}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Purchase From</label>
                        <input
                          className="form-control"
                          type="text"
                          onChange={handleChange("purchase_from")}
                          onBlur={handleBlur("purchase_from")}
                          value={values.purchase_from}
                        />
                        {errors.purchase_from && touched.purchase_from && (
                          <p className="text-danger">{errors.purchase_from}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Purchase Date</label>
                        <div>
                          <input
                            className="form-control "
                            type="date"
                            onChange={(e) => setPurchaseDate(e.target.value)}
                            value={purchaseDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group ">
                        <label>Purchased By </label>
                        <div className=" css-2b097c-container  position-relative">
                          <select
                            className="select form-control icons floating w-100"
                            onChange={handleChange("purchase_by")}
                            onBlur={handleBlur("purchase_by")}
                            value={values.purchase_by}
                          >
                            {purchasedBy?.map((items: any) => (
                              <option
                                key={items.id}
                                hidden={items.hidden}
                                value={items.value}
                              >
                                {items.label}
                              </option>
                            ))}
                          </select>
                          <BsCaretDownFill className="search-iconone position-absolute" />

                          {errors.purchase_by && touched.purchase_by && (
                            <p className="text-danger">{errors.purchase_by}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Amount</label>
                        <input
                          className="form-control"
                          type="text"
                          onChange={handleChange("amount")}
                          onBlur={handleBlur("amount")}
                          value={values.amount}
                        />
                        {errors.amount && touched.amount && (
                          <p className="text-danger">{errors.amount}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 position-relative">
                      <div className="form-group">
                        <label>Paid By</label>
                        <div className=" css-2b097c-container position-relative">
                          <select
                            className="select form-control icons floating w-100"
                            onChange={handleChange("paid_by")}
                            onBlur={handleBlur("paid_by")}
                            value={values.paid_by}
                          >
                            {paidBy?.map((items: any) => (
                              <option key={items.id} hidden={items.hidden}>
                                {items.name}
                              </option>
                            ))}
                          </select>
                          <BsCaretDownFill className="search-iconone position-absolute" />

                          {errors.paid_by && touched.paid_by && (
                            <p className="text-danger">{errors.paid_by}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*  <div className="col-sm-6">
                      <div className="form-group">
                        <label>Attachments</label>
                        <input
                          className="form-control"
                          type="file"
                          // value={values.attachments}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Attachments</label>
                        <input
                          className="form-control"
                          type="file"
                          name="file-upload"
                          id="file-upload"
                          accept="image/*"
                          onChange={(e) =>
                            imageHandler(e, setCategoryImgErr, setUploadImg)
                          }

                          // value={values.attachments}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-center">
                    <input
                      className="btn btn-primary submit-btn"
                      type="button"
                      onClick={() => handleSubmit()}
                      value=" Create Expense"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddExpenses;
