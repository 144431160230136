/** @format */

export const APILINK = {
  leave_list: "api/v1/leave",
  holidays: "api/v1/holiday",
  employee_login: "api/v1/auth",
  employee_get: "api/v1/employee",
  employee_add: "api/v1/register",
  role: "api/v1/role",
  attendance_insert: "api/v1/attendance",
  forgetpsw: "api/v1/forgetpsw",
  expenses: "api/v1/expense",
  attendance_list: "api/v1/attendancelist",
  leave_type: "/api/v1/leavetype",
  checkin_dashboard: "/api/v1/dashboard",
  payconfig: "/api/v1/payslip",
  project: "/api/v1/project",
  update: "/api/v1/project/3",
  activity: "/api/v1/activity",
  activity_delete: "/api/v1/activity/2",
};
