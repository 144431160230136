import React from "react";
import Layouts from "../layouts/Layouts";
import { Holiday } from "../components/customs/Holidays";

// type Props = {}

const Holidays = () => {
  return (
    <Layouts>
      <Holiday />
    </Layouts>
  );
};

export default Holidays;
